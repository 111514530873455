import Encrypted from 'components/Encrypted/Encrypted';
import { Content } from 'utils/content';
import { Link } from 'react-router-dom';
import LogisticHero from '../../assets/Images/logistic-hero-img.png';
import YartaLogistic from '../../assets/Images/yarta-logistic.png';

const Logistics = () => {
  const { logistics } = Content;
  return (
    <>
      <div className='bg-black-darkest pt-10'>       
        <div className=" w-full h-full pb-10 text-white relative">
          <div className="flex flex-col lg:flex-row wrapper h-full ">
            <div
              className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
              flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  >
              <p className='text-white md:text-6xl text-3xl lg:text-left text-center font-bold tracking-tight
              leading-10 max-w-[556px] w-full'>
                {logistics.tradeDirectly.titleBeforeSpan}
                <span className='text-gradient'>
                  {' '}
                  {logistics.tradeDirectly.titleSpan}{' '}
                </span>
              </p>
              <p className='text-white lg:text-xl mt-7 lg:text-left text-center max-w-[590px] w-full mx-auto sm:mx-0'>
                {logistics.tradeDirectly.subtitle}
              </p>
              <p className='text-white lg:text-xl mt-7 lg:text-left text-center w-full mx-auto sm:mx-0'>
                {logistics.tradeDirectly.subtitleInfo}
              </p>      
              <div className='sm:justify-start justify-center gap-y-7 mt-10 lg:mb-0 mb-12'>
                <Link
                  to='/logistics_waiting_list'
                  target='_blank'
                  className=' rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-black block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Join the Waitlist
                  </span>
                </Link>                
              </div>
            </div>
          </div>
          <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh
          lg:absolute top-0 h-fit">
            <div className="relative w-full h-full overflow-hidden">
              <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
                <img src={LogisticHero} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
                border-black-border border-t-8 border-l-8 ml-auto"></img>
              </div>
            </div>
          </div>
        </div>
        <Encrypted />
        {/* time you traded start */}
      </div>
      <div className='bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden'>
        <div className='flex flex-col lg:flex-row wrapper h-full'>
          <div className='md:w-full lg:w-1/2 flex-col text-center lg:text-left lg:pt-0 pt-8'>
            <h2 className='text-lg md:text-3xl font-semibold'>
              Let’s Get Started{' '}
            </h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>
              Ready to Connect? 
            </h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[591px] m-auto lg:m-0'>
              Contact our trade support team and we’ll keep in the loop on our upcoming releases.
            </p>
            <div className='flex w-full justify-center lg:justify-start'>
              <Link
                to='/logistics_waiting_list'
                target='_blank'
                className='sm:mt-10 mt-4 rounded-md flex justify-center 
                items-center  font-bold bg-darkest border-2 border-none
                relative overflow-hidden btn-hover max-w-[385px] w-full bg-black-dark  sm:mx-0 mx-auto'
                rel='noreferrer'>
                <span
                  className='absolute btn-effect
                transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='xl:text-xl text-base relative btn-text py-4 sm:px-8 px-3 tracking-wideF text-white'>
                  Join the Yarta Logistics Waitlist
                </span>
              </Link>
            </div>
          </div>
          <div
            className='w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[38%]  lg:translate-y-[40px]
            h-fit  align-bottom  translate-x-[31%] translate-y-[0px]'>
            <div className='pt-20 '>
              <img src={YartaLogistic} className=' rounded-tl-2xl rounded-bl-none' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logistics;
