import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import arrowimg from '../../assets/Images/arrowimg.png';

const BuildByFarmer = () => {
  const { builtByFarmer } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();

  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <div className="bg-black-darkest w-full  py-10  text-white">     
      <motion.div
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
        className="wrapper  xl:px-20 lg:px-20  flex-col !items-center lg:items-start md:mt-20 mt-10">
        <h2 className="text-3xl md:text-5xl sm:px-20 px-10 !text-center max-w-[834px] w-full md:text-left
        text-white font-bold mb-8">
          The Yarta value add. <br></br> Building a{' '}
          <span className="text-pink  block md:inline text-gradient">
            thriving network to elevate your trading.</span>         
        </h2>        
        <div className='flex  w-full 3xl:gap-64 2xl:gap-52 xl:gap-52 sm:gap-44 gap-10 xl:justify-start
        justify-center md:mb-16  mb-7 3xl:max-w-[1168px] 2xl:max-w-[945px] max-w-[765px]'>
          <div className='xl:rounded-lg sm:w-[29%] w-full flex items-center mb-5 '>
            <p className='text-white-sixty text-base'>
              We’re building a high-performance commodity trading, research and logistics platform - all
              in one convenient platform ecosystem.
            </p>
          </div>          
          <div className='flex flex-col text-white-sixty text-base text-lightpurple font-semibold'>
            <span>Research.</span>
            <span>Connect.</span>
            <span>Trade.</span>
            <span>Move.</span>
          </div>
        </div>
        <div className="flex w-full justify-center flex-wrap built-traders">
          {builtByFarmer.card.map((card, i) => (
            <div
              key={i}
              className="rounded-lg lg:w-3/5 xl:w-5/12  w-full sm:w-4/6  mr-4  px-6 flex 
                  bg-black-dark my-2 xl:mr-5 py-8 items-center"
            >
              <div className=" mr-4 flex justify-center lg:justify-start">
                <card.iconName />
              </div>
              <div>
                <p className="xl:text-lg 2xl:text-xl text-lg  font-bold text-white-light">
                  {card.title}
                </p>
                <p className="mt-1 text-white-sixty">{card.desc}</p>
                <Link to={card.link} className='flex items-center gap-2 mt-4  transition duration-300
                 ease-out hover:ease-in group'>
                  <div  className=" text-white-sixty md:text-lg text-base">{card.detail}</div>
                  <img src={arrowimg} alt='' 
                    className='w-4 group-hover:translate-x-2 transition duration-300'/>
                </Link> 
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default BuildByFarmer;
