/* eslint-disable max-len */
import { FC } from 'react';
import charts from '../Images/chart.png';

export const Chart: FC = () => {
  return (
    <>
      <img src={charts} alt='' 
        className='w-6'/>
    </>
  );
};

export default Chart;
