/* eslint-disable max-len */
import { FC } from 'react';

type YartLogoPropTypes = {
  width?: number | string;
  height?: number | string;
  color?: string;
  name?: string;
};

export const YartLogo: FC<YartLogoPropTypes> = ({
  width,
  height,
  color = '#fff',
}) => {
  const style = {
    fill: color,
  };

  return (
    <div className="z-40">
      <svg
        width={width || 120}
        height={height || 120}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1296.37 400"
        style={style}
      >
        <path
          className="cls-1"
          d="M231.38,362.34c.4,21.53,18.46,38.85,39.95,37.6l1.44-.11c19.58-1.77,34.42-18.48,34.42-38.14V294.52a94,94,0,0,1,22.64-61.2l73.53-73.54C416.8,146.35,416.18,124,401.5,112c-9.89-8.13-25.3-11.27-49.69,3L240.1,223.72a29.2,29.2,0,0,0-8.83,21Z"
        />
        <path
          className="cls-1"
          d="M185.77,360.27c0,21.54-17.73,39.19-39.25,38.33-.47,0-1,0-1.43-.08-19.61-1.4-34.76-17.84-35.13-37.49l-1.24-67.16A94.06,94.06,0,0,0,85,233.1L10.07,160.94c-13.68-13.19-13.48-35.52,1-47.85,9.74-8.31,25.09-11.74,49.73,2L174.49,221.84a29.24,29.24,0,0,1,9.22,20.79Z"
        />
        <path
          className="cls-1"
          d="M206.51,185.87,320.85,76.49a59.37,59.37,0,0,0,15.52-24.37c4.59-14.13,1.69-24.31-3.94-31.7-11.5-15.12-33.79-16.56-47.72-3.63L216.52,79.56,206.24,89.84l-79-72.73C113.25,4.27,91,5.84,79.56,21,74,28.45,71.14,38.65,75.81,52.75a59.1,59.1,0,0,0,15.73,24.3Z"
        />
        <path
          className="cls-1"
          d="M584.67,164.17l44.91-76.34h38.48L601.35,202V311.06H568V202L501.28,87.83h38.49Z"
        />
        <path
          className="cls-1"
          d="M825.5,311.06H794.07V291.82l-.65.64q-19.87,18.6-53.24,18.6-30.79,0-57.41-22.77t-26.62-61.9q0-39.78,26.3-61.9t57.73-22.13q35.93,0,53.24,17.32c.43.42.65.21.65-.65V143.64H825.5Zm-46.83-46.5q16-15.72,16-37.85t-16-38.49q-16-16.35-38.49-16.36t-38.49,16.36q-16,16.36-16,38.49t16,37.85a55,55,0,0,0,77,0Z"
        />
        <path
          className="cls-1"
          d="M861.26,209.07V143.64H894v6.41q14.12-8.34,34.64-8.34a92.51,92.51,0,0,1,22.45,2.57l-10.26,30.15a79.79,79.79,0,0,0-12.19-1.92q-34.65,0-34.64,36.56v102H861.26Z"
        />
        <path
          className="cls-1"
          d="M995.67,144.92h7.7v30.15h-16Zm7.7-55.81h30.15V261q0,3.21,3.53,9.62a19.32,19.32,0,0,0,9.94,9c2.51,1,6.15,1.43,10.91,1.29s8.76-2.14,12.19-6.42L1099,284.76q-3.84,9.63-15.71,17.64t-29.19,8a64.92,64.92,0,0,1-12.83-1.28Q1020.7,305.28,1012,288a79.43,79.43,0,0,1-8.66-35.92Zm30.15,55.81H1099l-9,30.15h-56.45Z"
        />
        <path
          className="cls-1"
          d="M1296.37,311.06h-31.43V291.82l-.64.64q-19.89,18.6-53.25,18.6-30.78,0-57.41-22.77T1127,226.39q0-39.78,26.3-61.9t57.73-22.13q35.92,0,53.25,17.32c.42.42.64.21.64-.65V143.64h31.43Zm-46.83-46.5q16-15.72,16-37.85t-16-38.49q-16-16.35-38.49-16.36t-38.48,16.36q-16,16.36-16,38.49t16,37.85a55,55,0,0,0,77,0Z"
        />
      </svg>
    </div>
  );
};

export default YartLogo;
