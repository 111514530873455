import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import startTrading from 'assets/Images/platform_example.mp4';
import { Content } from 'utils/content';
import { Link } from 'react-router-dom';

const StartTradingToday = () => {
  const { startTradingToday } = Content;

  const control = useAnimation();
  const [ref, inView] = useInView();

  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <div className="bg-black w-full md:py-28 pt-20 pb-16 text-white">
      <motion.div
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
        className="wrapper flex-col items-center justify-center"
      >
        <h2 className="md:text-5xl text-3xl text-white  text-center font-bold ">
          {startTradingToday.title}
        </h2>
        <div className="flex flex-col w-full items-center justify-center">
          <p className="text-white-sixty md:my-10 mt-5 mb-10 xl:w-4/5 2xl:w-3/5 md:px-24 2xl:text-xl lg:px-28 
          lg:text-lg text-base text-center">
            {startTradingToday.description}
          </p>
          <div className="red-gradient relative border-gradient-one max-w-full">
            <video
              src={startTrading}
              width="100%"
              className="rounded-md "
              autoPlay
              loop
              muted
            >
              Your Browser does not support videos
            </video>  
          </div>
        </div>
        <Link 
          to="/waiting_list"
          className="h-10 md:mt-20 mt-10 mb-20 rounded-md flex justify-center items-center 
          w-40 border-2 border-purple font-bold rvs-btn-hover overflow-hidden btn-hover
            relative"
        >
          <span className='absolute btn-effect transition-all duration-300 transi top-0 
          left-0 bg-purple block h-full w-full'></span>
          <span className='relative btn-text'>Start Trading</span>
        </Link>
      </motion.div>
    </div>
  );
};

export default StartTradingToday;
