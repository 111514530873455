import Logo from '../../assets/Icons/YartLogo';
import HubspotForm from 'react-hubspot-form'
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/Images/left-arrow.svg';
import TechnicalChart from 'assets/Images/technical-chart.png';

const RequestDemo = () => {
  return (
    <>
      <div className="waitinglist text-white flex flex-wrap min-h-screen">
        <div className="w-full md:w-[35%] bg-waitinglist md:pt-[50px] md:pb-[50px] pb-[30px] md:pl-10
         lg:pt-[70px] lg:pl-20 lg:pb-[70px] pt-7 pl-5 overflow-hidden">
          <div>
            <h2 className="text-[24px] md:text-[24px] lg:text-[30px] xl:text-[36px] 2xl:text-[56px]
             font-semibold leading-tight pb-5 md:pr-0 pr-2">
              This simple way<br /> to trade commodities  <br />with other traders
            </h2>
            <p className="text-[#ffffffb3] lg:text-[18px] xl:text-[20px] 2xl:text-[28px] 
            max-w-[445px] md:pb-[70px] pb-8 md:pr-0 pr-2">
              An <span className="text-white">intuitive and simple </span>
              experience right from the start
            </p>
            <img className='rounded-md border-black-border border-t-8 border-l-8 md:ml-auto 
            2xl:-mr-28 md:-mr-0 -mr-16 mx-auto box-shadow md:max-w-[100%] max-w-[100%] w-full' src={TechnicalChart} 
            alt="" />
          </div>
        </div>
        <div className="w-full md:w-[65%] md:px-[40px] lg:px-[60px] xl:px-[100px] 2xl:px-[120px] pb-10">
          <div className='pt-[40px] px-5 md:px-0 md:pt-[60px] md:max-w-[600px] max-w-[350px] m-auto'>
            <div className='block'>
              <div className='waitinglist-logo'>
                <Logo width={115} height={30} />
              </div>
              <h3 className='md:text-[24px] xl:text-[30px] 2xl:text-[40px] py-5 md:py-5 
              lg:pt-8 lg:pb-[25px] font-semibold text-left'>
                Yes, I want a platform tour
              </h3>
              <p className='text-left text-[#ffffff99] md:text-md lg:text-lg xl:text-xl 
              2xl:text-2xl md:pb-5 lg:pb-10 pb-5'>
                We’re building a unique, world-class all-in-one
                platform to take your trading, research and
                logistics management to a whole new level.
              </p>
              <p className='text-left text-[#ffffff99] md:text-md lg:text-lg xl:text-xl 
              2xl:text-2xl md:pb-5 lg:pb-10 pb-5'>
                We coulden’t be more exited to share the platform with you. 
              </p>
            </div>
            <div className='block pt-[50px] md:pt-[80px] lg:pt-6'>
              <HubspotForm 
                portalId='21451866'
                region= "na1"
                formId='ac0cc1c7-be6d-4793-bd6c-43c576a5f213'
                onSubmit={() => console.log('Submit!')}
                loading={<div>Loading...</div>}
              />
            </div>
            <div className='mt-3 md:mt-3 lg:mt-0'>
              <Link className='flex items-center' to="/">
                <img 
                  className='pr-3'
                  src={leftArrow} 
                  alt=""
                />
                Back to Site
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RequestDemo;
