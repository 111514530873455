import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const Encrypted = () => {
  const { encrypted } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();

  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <div className="bg-black-darkest w-full md:pt-[108px] pt-10  text-white md:pb-32 pb-16">    
      <motion.div
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
        className="wrapper  xl:px-20 lg:px-20  flex-col items-center lg:items-start"
      >
        <h2 className="text-3xl md:text-5xl sm:px-20 px-10 text-center md:text-left text-white font-bold mb-8
          max-w-[1117px] w-full">
          Yarta gives you a {' '}
          <span className="text-pink  block md:inline text-gradient">
            secure and private 
          </span> space where you’re in control. 
        </h2>        
        <div className='flex  w-full 3xl:gap-44 2xl:gap-36 xl:gap-28 sm:gap-44 gap-10 xl:justify-start
        justify-center md:mb-16  mb-7 xl:ml-24 3xl:ml-28 '>
          <div className='xl:rounded-lg sm:w-[29%] w-full flex sm:mr-5 items-center mb-5 '>
            <p className='text-white-sixty text-base'>
              Connect with livestock brokers, negotiate pricing,
              and manage your livestock movements with your team in one place.
            </p>
          </div>          
          <div className='flex flex-col text-white-sixty text-base text-lightpurple font-semibold'>
            <span>Research.</span>
            <span>Connect.</span>
            <span>Trade.</span>
            <span>Move.</span>
          </div>
        </div>
        <div className="flex w-full justify-center flex-wrap built-traders">
          {encrypted.card.map((card, i) => (
            <div
              key={i}
              className="rounded-lg lg:w-3/5 xl:w-5/12  w-full sm:w-4/6  mr-4  px-5 flex 
                xl:h-32 bg-black-dark my-2 xl:mr-5 py-4 items-center"
            >
              <div className="h-10 mr-4 flex justify-center lg:justify-start">
                <card.iconName />
              </div>
              <div>
                <p className="xl:text-lg 2xl:text-xl text-lg  font-bold text-white-light">
                  {card.title}
                </p>
                <p className="mt-1 text-purple font-bold"><b>{card.span}</b>
                  <span className='text-white-sixty font-medium'>{card.desc}</span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Encrypted;
