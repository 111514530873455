import React from 'react';
import Typewriter from 'typewriter-effect';
import { Content } from 'utils/content';
import { Link } from 'react-router-dom';
import TradeCommoditiesImg from 'assets/Images/homehero.png';
import CentralEcosystem from 'components/CentralEcosystem/CentralEcosystem';
import TradePhysical from 'components/TradePhysical/TradePhysical';
import NewHomeMission from 'components/OurMission/NewHomeMission';
import Trandimg from '../../assets/Images/trandimg.png';
import platdashboard from '../../assets/Images/platformdash.png';
// import dashboard from 'assets/Images/yartaBlock.png';

const NewHome = () => {
  const { newHome } = Content;

  return (
    <>
      <div className='bg-black-darkest pt-10'>
        <div className="w-full h-full pb-10 text-white relative">
          <div className="flex flex-col lg:flex-row wrapper h-full ">
            <div
              className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
            flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  
            >
              <p className='text-white md:text-6xl text-3xl font-bold tracking-tight leading-10 max-w-[531px]w-full '>
                {newHome.tradeDirectly.titleBeforeSpan} 
                          
                <div className='hidden md:flex justify-center lg:justify-start items-center'>
                  <span className='!text-white mr-3'>the</span>
                  <span className='type xl:text-6xl lg:text-5xl sm:text-5xl text-3xl'>
                    <Typewriter
                      options={{
                        strings: ['smart', 'intelligent', 'easy', 'informed'],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </span>                  
                  <span className='text-white mr-3'>way.</span>
                </div>
                             
              </p>
              <span className='md:hidden block text-3xl font-bold'> the<span className='text-gradient'
              > smart</span> way. </span> 
              <div className="w-full contents justify-center lg:justify-start">
                <p className='text-white md:text-2xl sm:text-xl text-center lg:text-left text-base mt-7
                max-w-[539px] w-full mx-auto sm:mx-0'>
                  {newHome.tradeDirectly.subtitle}
                </p>
                <p
                  className="text-white lg:text-xl text-center lg:text-left
                sm:w-4/5 w-full md:w-[90%] md:pr-10 mt-9"
                >
                  {newHome.tradeDirectly.description}
                </p>
              </div>          
              <div className='flex 2xl:gap-x-5 xl:gap-1 gap-x-5 sm:justify-start justify-center 
                xl:flex-nowrap flex-wrap gap-y-7 mt-10 lg:mb-0 mb-12'>
                <Link
                  to='/waiting_list'
                  target='_blank'
                  className=' rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-black block h-full w-full'
                  ></span>
                  <span className='relative btn-text 2xl:p-3 lg:p-2 px-2 py-3 tracking-wide text-white'>
                    Join the Yarta Trade Waitlist
                  </span>
                </Link>
                <Link
                  to='/market_intel_waiting_list'
                  target='_blank'
                  className='rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                  ></span>
                  <span className='relative btn-text 2xl:p-3 lg:p-2 px-2 py-3 tracking-wide text-white'>
                    Join the Yarta Market Intel Waitlist
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh
          lg:absolute top-0 h-fit">
            <div className="relative w-full h-full overflow-hidden">
              <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
                <img src={TradeCommoditiesImg} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
                border-black-border border-t-8 border-l-8 ml-auto"></img>
              </div>
            </div>
          </div>
        </div>
        <CentralEcosystem />
        <TradePhysical />
        <NewHomeMission />
        {/* {/ time you traded start /} */}
        <div className=' bg-black'>
          <div className='text-white max-w-[1280px] w-full text-center mx-auto wrapper flex-col md:py-20 py-16'>
            <h2 className='lg:text-[56px] md:text-5xl block text-4xl font-bold tracking-tight'>
              It’s time you traded the {' '}
              <span className='text-purple inline-block'>
                <Typewriter
                  options={{
                    strings: ['smart', 'intelligent', 'easy', 'informed'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
              <span className='text-white mr-3'> way</span>          
            </h2>
            <p className='lg:text-2xl md:text-xl text-base text-white-sixty max-w-[710px] w-full  mt-8'>
              Leave the chaotic mix of calls, messages, and emails behind.
              Research, connect, and trade in one easy to use platform.
            </p>
            <div className='flex gap-x-7 gap-y-7 justify-center sm:flex-nowrap flex-wrap mt-8'>
              <Link
                to='/waiting_list'
                target='_blank'
                className=' rounded-md flex justify-center 
              items-center  font-bold border border-purple
              relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                rel='noreferrer'
              >
                <span
                  className='absolute btn-effect
               transition-all duration-300 top-0 
              left-[-100%] bg-black block h-full w-full'
                ></span>
                <span className='relative btn-text p-3 tracking-wide text-white'>
                  Join the Yarta Trade Waitlist
                </span>
              </Link>
              <Link
                to='/market_intel_waiting_list'
                target='_blank'
                className='rounded-md flex justify-center 
              items-center  font-bold border border-purple
              relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
                rel='noreferrer'
              >
                <span
                  className='absolute btn-effect
               transition-all duration-300 top-0 
              left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='relative btn-text p-3 tracking-wide text-white'>
                  Join the Yarta Market Intel Waitlist
                </span>
              </Link>
            </div>
            <div className='max-w-[1280px] w-full md:mt-20 mt-10 red-gradient border-gradient-one'>
              <img src={Trandimg} alt='' />
            </div>
          </div>
        </div>
        {/* {/ time you traded end /} */}
      </div>          
      <div className="bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden">
        <div className="flex flex-col lg:flex-row wrapper h-full">
          <div className="md:w-full lg:w-1/2 flex-col text-center lg:text-left pt-10 xl:pt-0">
            <h2 className='text-lg md:text-3xl font-semibold'>Let’s Get Started</h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>Ready to Connect?</h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[580px] m-auto lg:m-0'>
              Contact our trade support team today to arrange your online
              one-on-one demo - and see for yourself how the Yarta
              Trade Platform can simplify and enhance your trading.
            </p>
            <div className="flex w-full lg:w-4/6 justify-center lg:justify-start">
              <a 
                href="/request_demo" 
                className='h-10 lg:my-10 mt-10 mb-14 rounded-md flex justify-center 
              items-center w-40 font-bold text-white bg-black-darkest btn-hover 
              overflow-hidden btn-hover relative'
                target="|_blank">
                <span className='absolute btn-effect transition-all duration-300 transi top-0 
              left-[-100%] bg-purple block h-full w-full'></span>
                <span className='relative btn-text'>Request Demo</span>
              </a>
            </div>  
          </div>
          <div className="w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[35%] lg:translate-y-[96px]
 h-fit flex align-bottom translate-x-[18%] translate-y-[18px]">
            <div className="rounded-tl-md rounded-bl-none">
              <img src={platdashboard} className="xl:w-auto w-full"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHome;
