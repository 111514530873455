import Logo from '../../assets/Icons/YartLogo';
import HubspotForm from 'react-hubspot-form'
import { Link } from 'react-router-dom';
import leftArrow from '../../assets/Images/left-arrow.svg';
import bgWaitinglist from 'assets/Images/bgwaitinglist.png';

const Waitinglist = () => {
  return (
    <>
      <div className="waitinglist text-white flex flex-wrap min-h-screen">
        <div className="w-full md:w-[35%] bg-waitinglist md:pt-[50px] md:pb-[50px] pb-[30px] md:pl-10
         lg:pt-[70px] lg:pl-20 lg:pb-[70px] pt-7 pl-5 overflow-hidden">
          <div>
            <h2 className="text-[24px] md:text-[24px] lg:text-[30px] xl:text-[36px] 2xl:text-[56px]
             font-semibold leading-tight pb-5 md:pr-0 pr-2">
              Buy and sell <br /> commodities with ease
            </h2>
            <p className="text-[#ffffffb3] lg:text-[18px] xl:text-[20px] 2xl:text-[28px] 
            max-w-[300px] md:pb-[70px] pb-8 md:pr-0 pr-2">
              An <span className="text-white">intuitive and simple</span> experience right from the start
            </p>
            <img className='rounded-md border-black-border border-t-8 border-l-8 ml-auto
            -mr-2 box-shadow md:max-w-[100%] max-w-[80%]' src={bgWaitinglist} 
            alt="" />
          </div>
        </div>
        <div className="w-full md:w-[65%] md:px-[40px] lg:px-[60px] xl:px-[100px] 2xl:px-[120px] pb-10">
          <div className='pt-[40px] px-5 md:px-0 md:pt-[60px] md:max-w-[595px] max-w-[350px] m-auto'>
            <div className='block'>
              <div className='waitinglist-logo'>
                <Logo width={115} height={30} />
              </div>
              <h3 className=' md:text-[24px] xl:text-[30px] 2xl:text-[40px] py-5 md:py-5 
              lg:pt-8 lg:pb-[25px] font-semibold text-center '>
                Join the waitlist for Yarta Trade platform
              </h3>
              <p className='text-[#ffffff99] md:text-md lg:text-lg xl:text-xl 
              2xl:text-2xl md:pb-5 lg:pb-10 pb-5 text-center'>
                We’ve built a trading platform designed for the pro.
              </p>
              <p className='text-[#ffffff99] md:text-md lg:text-lg xl:text-xl 2xl:text-2xl text-center'>
                Join the waitlist to receive updates on our upcoming launch as we finalise beta testing.
              </p>
            </div>
            <div className='block pt-[50px] md:pt-[80px] lg:pt-[53px]'>
              <HubspotForm
                portalId='21451866'
                // formId='63f07a76-528b-4cdb-aec4-f99c34344ebf'
                formId='8d02d531-c6be-4eb9-b25b-4d6da604b5dc'
                onSubmit={() => console.log('Submit!')}
                loading={<div>Loading...</div>}
              />
            </div>
            <div className='mt-3 md:mt-3 lg:mt-0'>
              <Link className='flex items-center' to="/">
                <img 
                  className='pr-3'
                  src={leftArrow} 
                  alt=""
                />
                Back to Site
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Waitinglist;
