import Trademarket from 'components/TradeMarket';
import { Content } from 'utils/content';
import chatVideo from 'assets/Images/video1.mp4';
import orderVideo from 'assets/Images/video2.mp4';
import { Link } from 'react-router-dom';
import platdashboard from '../../assets/Images/platformdash.png';
import Blockimg from '../../assets/Images/blockimg.png';
import Trandimg from '../../assets/Images/trandimg.png';
import Creat1 from '../../assets/Images/creat1.png';
import Creat2 from '../../assets/Images/creatstep2.png';
import Creat3 from '../../assets/Images/creatstep3.png';
import Negotiating from '../../assets/Images/negotiating.png';
import Creatcomplete from '../../assets/Images/creatcomplete.png';
import { motion, useAnimation,  } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';

const Blockplatform = () => {
  const { blockplatform } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();

  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 620;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  return (
    <>
      <div className='bg-black-darkestpt-10 md:pb-28 pb-8'>      
        <div className="bg-black-darkest w-full h-full pb-10  lg:mb-20  text-white relative">
          <div className="flex flex-col lg:flex-row wrapper h-full">
            <div
              className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
              flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  >
              <p className='text-white md:text-6xl text-3xl lg:text-left text-center font-bold tracking-tight
              leading-10 max-w-[447px] w-full'>
                {blockplatform.tradeDirectly.titleBeforeSpan}
                <span className='text-gradient'>
                  {' '}
                  {blockplatform.tradeDirectly.titleSpan}{' '}
                </span>
              </p>
              <p className='text-white lg:text-xl mt-7 lg:text-left text-center max-w-[590px] w-full mx-auto sm:mx-0'>
                {blockplatform.tradeDirectly.subtitle}
              </p>
                    
              <div className='flex gap-x-5 sm:justify-start justify-center flex-wrap gap-y-7 mt-10 lg:mb-0 mb-12'>
                <Link
                  to='/waiting_list'
                  target='_blank'
                  className=' rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-black block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Join the Yarta Trade Waitlist
                  </span>
                </Link>
                <a
                  href='https://calendly.com/les-yarta/30min'
                  target='_blank'
                  className='rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Request a Demo           
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh
          lg:absolute top-0 h-fit">
            <div className="relative w-full h-full overflow-hidden">
              <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
                <img src={Blockimg} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
                border-black-border border-t-8 border-l-8 ml-auto"></img>
              </div>
            </div>
          </div>
        </div>
        <Trademarket />
        <motion.div
          ref={ref}
          variants={variant}
          initial="hidden"
          animate={control}>
          <div className='wrapper flex-col '>
            <div className='text-white max-w-[890px] w-full lg:mb-20 md:mb-12 mb-9'>
              <h3 className='text-base font-medium'>
                HOW <span className='text-gradient'>IT WORKS</span>
              </h3>
              <h2 className='lg:text-[56px] md:text-5xl text-4xl font-medium tracking-tight mt-6'>
                Leveraging data and automation to streamline physical trading.{' '}
              </h2>
              <p className='lg:text-2xl md:text-xl text-base text-white-sixty mt-6'>
                Yarta helps trading teams save time, provides unique data-driven
                market insights, and improves the bottom line.
              </p>
              <p className='lg:text-2xl md:text-xl text-base text-white-sixty mt-7'>
                Negotiate, trade, and execute trades with the biggest players in
                the market with ease.{' '}  
              </p>
            </div>
          </div>      
        </motion.div>        
        {/* time you traded start */}       
        <div className=' bg-black'>
          <div className='text-white max-w-[1280px] w-full text-center mx-auto wrapper flex-col md:py-20 py-16'>
            <h2 className='lg:text-[56px] md:text-5xl text-4xl font-medium tracking-tight'>
              It’s time you traded the<span className='text-purple'> easy</span> way
            </h2>
            <p className='lg:text-2xl md:text-xl text-base text-white-sixty max-w-[710px] w-full  mt-8'>
              Forget the chaotic mix of calls, messages, and emails. Research,
              connect, and trade in on easy  to use platform.
            </p>
            <div className='flex gap-x-7 gap-y-7 justify-center sm:flex-nowrap flex-wrap mt-8'>
              <Link
                to='/waiting_list'
                target='_blank'
                className=' rounded-md flex justify-center 
              items-center  font-bold border border-purple
              relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                rel='noreferrer'
              >
                <span
                  className='absolute btn-effect
              transition-all duration-300 top-0 
              left-[-100%] bg-black block h-full w-full'
                ></span>
                <span className='relative btn-text p-3  md:text-xl text-base  tracking-wide text-white'>
                  Join the Yarta Trade Waitlist
                </span>
              </Link>
              <a
                href='https://calendly.com/les-yarta/30min'
                target='_blank'
                className='rounded-md flex justify-center 
              items-center  font-bold border border-purple
              relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
                rel='noreferrer'
              >
                <span
                  className='absolute btn-effect
              transition-all duration-300 top-0 
              left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='relative btn-text p-3  md:text-xl text-base  tracking-wide text-white'>
                  Request a Demo
                </span>
              </a>
            </div>
            <div className='max-w-[1280px] w-full md:mt-20 mt-10 red-gradient border-gradient-one'>
              <img src={Trandimg} alt='' />
            </div>
          </div>
        </div>            
        {/* time you traded end */}
        {/*3 simple steps to trade-strat */}        
        <div>
          <div className='wrapper text-white flex-col lg:pt-36 md:pt-16 sm:pt-14 pt-8 pb-20'>
            <h2 className='lg:text-[56px] md:text-4xl text-3xl font-semibold lg:pb-20 md:pb-11 pb-8 text-center
            md:text-left md:!leading-[50px]'>
              All it takes is 3 simple steps to trade
            </h2>
            <div className='grid xl:grid-cols-4 sm:grid-cols-2 gap-4 gap-y-5'>
              <div className='rounded-[20px] bg-black-dark px-5 md:pt-10 pt-5 pb-7 max-w-[276px] w-full'>
                <div className=''>
                  <div className='min-h-[93px]'>
                    <h2 className='text-2xl font-semibold'>Step 1</h2>
                    <h3 className='text-lg  sm:pt-5  pt-4 lg:min-h-[88px] 2xl:min-h-0'>Create an order</h3>
                  </div>
                  <p className='text-white-sixty md:pt-0 2xl:pt-2 sm:pt-8 pt-4 min-h-[155px] xl:min-h-[158px]'>
                    A trader places anonymously tests the market with a Request
                    For Quote (RFQ) order. This can be as a flat price, location
                    or quality spread.
                  </p>
                </div>
                <div className='md:pt-1 pt-3'>
                  <img src={Creat1} alt=''  
                    className='h-[193px]'/>
                </div>
              </div>              
              <div className='rounded-[20px] bg-black-dark px-5 md:pt-10 pt-5 pb-7 max-w-[276px] w-full'>
                <div className=''>
                  <div className='min-h-[93px]'>
                    <h2 className='text-2xl font-semibold'>Step 2</h2>
                    <h3 className='text-lg sm:pt-5 pt-4 lg:min-h-[88px] 2xl:min-h-0'>
                      Negotiate terms
                    </h3>
                  </div>
                  <p className='text-white-sixty md:pt-0 2xl:pt-2 sm:pt-8 pt-4 sm:min-h-[155px] xl:min-h-[158px]'>
                    Other traders anonymously negotiate terms via the encrypted
                    chat and can make a firm bid/offer.
                  </p>
                </div> 
                <div className='md:pt-1 pt-3'>
                  <img src={Creat2} alt='' 
                    className='h-[193px]'/>
                </div>                
              </div>
              <div className='rounded-[20px] bg-black-dark px-5 md:pt-10 pt-5 pb-7 max-w-[276px] w-full'>
                <div className=''>
                  <div className='min-h-[93px]'>
                    <h2 className='text-2xl font-semibold'>Step 3</h2>
                    <h3 className='text-lg  sm:pt-5 pt-4 lg:min-h-[88px] 2xl:min-h-0'>
                      Accept or reject or counter
                    </h3>
                  </div>
                  <p className='text-white-sixty md:pt-0 2xl:pt-2 sm:pt-5 pt-4 sm:min-h-[155px] xl:min-h-[158px]'>
                    The RFQ creator can review bids/offers, renegotiate,
                    counter, accept or reject a proposal.
                  </p>
                </div> 
                <div className='md:pt-1 pt-3'>
                  <img src={Creat3} alt='' 
                    className='h-[193px]'/>
                </div>                
              </div>
              <div className='rounded-[20px] bg-black-dark px-5 md:pt-10 pt-5 pb-7 max-w-[276px] w-full'>
                <div className=''>
                  <div className='sm:min-h-[93px]'>
                    <h2 className='text-2xl lg:min-h-[88px] font-semibold'>Trade Complete</h2>
                  </div>
                  <p className='text-white-sixty md:pt-0 2xl:pt-2F sm:pt-8 pt-4 sm:min-h-[155px] xl:min-h-[158px]'>
                    After accepting a firm bid/offer, the trade is complete,
                    confirmations of the trade are autogenerated and
                    counterparties are revealed.
                  </p>
                </div> 
                <div className='md:pt-1 pt-3'>
                  <img src={Creatcomplete} alt='' 
                    className='h-[193px] 2xl:translate-y-0 lg:translate-y-7 translate-y-0'/>
                </div>                
              </div>
            </div>
          </div>
        </div>
        {/*3 simple steps to trade-end */}

        <div className='wrapper lg:!mb-28 !mb-20 justify-center  gap-x-8 lg:gap-28 gap-y-8 sm:flex-row
          flex-col lg:mt-10 '>
          <div className=' md:my-10 xl:w-[36%] sm:w-1/2 w-full sm:text-left text-center sm:mb-0 mb-8'>
            <p className='text-white md:text-5xl text-3xl'>
              {blockplatform.tradeDirect.title}
            </p>
            <p className='text-white-sixty md:text-2xl sm:text-xl text-base mt-4'>
              {blockplatform.tradeDirect.subtitle}
            </p>
          </div>
          <div className='xl:w-4/13 sm:w-[40%] w-full xl:mr-28  border-gradient relative'>
            { width < breakpoint ? 
              <video
                src={chatVideo}
                width='100%'
                className='rounded-md'
                loop
                muted
              >
                Your Browser does not support videos
              </video>
              :
              <video
                src={chatVideo}
                autoPlay
                width='100%'
                className='rounded-md'
                loop
                muted
              >
                Your Browser does not support videos
              </video>
            }
          </div>
        </div>
        <div className='wrapper lg:!mb-32 !mb-20 justify-center sm:flex-row flex-col-reverse gap-x-8 lg:gap-28
          gap-y-8 !lg:mt-36'>
          <div className='xl:w-4/13 sm:w-[40%] w-full  border-gradient relative'>
            { width < breakpoint ?
              <video
                src={orderVideo}
                width='100%'
                className='rounded-md'
                loop
                muted
              >
                Your Browser does not support videos
              </video>
              : 
              <video
                autoPlay
                src={orderVideo}
                width='100%'
                className='rounded-md'
                loop
                muted
              >
                Your Browser does not support videos
              </video>
            }
          </div>
          <div className='xl:pl-10 md:my-10 xl:w-[36%] sm:w-1/2 w-full  sm:text-left text-center sm:mb-0 mb-8'>
            <p className='text-white md:text-5xl text-3xl'>
              {blockplatform.tradeCash.title}
            </p>
            <p className='text-white-sixty md:text-2xl sm:text-xl text-base mt-4 max-w-[540px] w-full'>
              {blockplatform.tradeCash.subtitle}
            </p>
          </div>
        </div>
        <div className='wrapper lg:!mb-32 !mb-20 justify-center  gap-x-8 lg:gap-28 gap-y-8 sm:flex-row flex-col'>
          <div className=' md:my-10 xl:w-[36%]  w-full sm:text-left text-center sm:mb-0 mb-8'>
            <p className='text-white md:text-5xl text-3xl'>
              {blockplatform.tradeplatform.title}
            </p>
            <p className='text-white-sixty md:text-2xl sm:text-xl text-base mt-4'>
              {blockplatform.tradeplatform.subtitle}
            </p>
          </div>
          <div className='border-gradient relative'>
            <img src={Negotiating} alt='' 
              className='max-w-[748px] w-full'/>            
          </div>
        </div>
      </div>
      <div className='bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden'>
        <div className='flex flex-col lg:flex-row wrapper h-full'>
          <div className='md:w-full lg:w-1/2 flex-col text-center lg:text-left lg:pt-0 pt-8'>
            <h2 className='text-lg md:text-3xl font-semibold'>
              Let’s Get Started{' '}
            </h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>
              Ready to connect?
            </h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[580px] m-auto lg:m-0'>
              Contact our trade support team today to arrange your online
              one-on-one demo - and see for yourself how the Yarta
              Trade Platform can simplify and enhance your trading.
            </p>
            <div className='flex w-full lg:w-4/6 justify-center lg:justify-start relative z-[99999]'>
              <a
                href='https://calendly.com/les-yarta/30min'
                target='_blank'
                className='hidden lg:flex sm:mt-10 mt-4 rounded-md  justify-center 
                items-center  font-bold bg-darkest border-2 border-none
                relative overflow-hidden btn-hover max-w-[200px] w-full bg-black-dark  sm:mx-0 mx-auto'
                rel='noreferrer'>
                <span
                  className='absolute btn-effect
                transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='relative btn-text p-3 tracking-wider text-white'>
                  Request Demo
                </span>
              </a>
              <a
                href='/request_demo'
                target='_blank'
                className='lg:hidden sm:mt-10 mt-4 rounded-md flex justify-center 
                items-center  font-bold bg-darkest border-2 border-none
                relative overflow-hidden btn-hover max-w-[200px] w-full bg-black-dark  sm:mx-0 mx-auto'
                rel='noreferrer'>
                <span
                  className='absolute btn-effect
                transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='relative btn-text p-3 tracking-wider text-white'>
                  Request Demo
                </span>
              </a>
            </div>
          </div>
          <div
            className='w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[38%]  lg:translate-y-[45px]
            h-fit  align-bottom  translate-x-[31%] translate-y-[-49px]'>
            <div className='pt-20'>
              <img src={platdashboard} className='rounded-tl-md rounded-bl-none'/>
            </div>  
          </div>
        </div>
      </div>
    </>
  );
};

export default Blockplatform;
