import Logo from '../../assets/Icons/YartLogo';
import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import linkIn from '../../assets/Images/linkeIn.svg';
import Twitter from '../../assets/Images/twitter.svg';
import { Link } from 'react-router-dom';
import { Ticker } from 'react-ts-tradingview-widgets';
// import temrPdf from '../../assets/Images/Yarta-Privacy-Policy.pdf'


const Footer = () => {
  const { footer, footerLinks } = Content;

  const control = useAnimation();
  const [ref, inView] = useInView();
  
  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 20, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  useEffect(() => {
    const handleResize = () => {
      let box = document.querySelector('#tradingview_widget_wrapper div a span') as HTMLDivElement;
      box.innerHTML = 'Quotes ' 
    }
    window.addEventListener('resize', handleResize);
    handleResize();
  })

  const data = [
    {
      'title': 'CBOT Wheat Front Month Future',
      'proName': 'CBOT:ZW1!'
    },
    {
      'title': 'CBOT Corn Front Month Future',
      'proName': 'CBOT:ZC1!'
    },
    {
      'title': 'CBOT Soybeans Front Month Future',
      'proName': 'CBOT:ZS1!'
    },
    {
      'title': 'CBOT Soybean Meal Front Month Future',
      'proName': 'CBOT:ZM1!'
    },
    {
      'title': 'CBOT Soybean Oil Front Month Future',
      'proName': 'CBOT:ZL1!'
    }
  ]

  return (
    <footer
      className=" w-full bg-black-darkest
     text-white flex   box-border flex-col"
    >
      <Ticker colorTheme='dark' symbols={data}></Ticker>
      <motion.div
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
        className="wrapper flex-col py-10 lg:pt-20 wrapper-footer-main justify-between !items-start md:pb-7"
      >
        <div className="wrapper-footer-left">
          <div className="flex justify-center lg:justify-start flex-col">
            <Logo color="#fff" />
            <p className="tracking-wider transform -translate-y-6">
              trade on your terms
            </p>
          </div>
          <h2 className="text-white text-left font-bold my-4 md:text-3xl text-2xl">
            {footer.title}
          </h2>
          <p className="text-white-sixty mb-4 md:max-w-[215px] w-full lg:mr-0 sm:mr-3 mr-0">
            {footer.subtitle}
            <Link to='/contact'>{footer.subtitlespan}</Link>
            <span>{footer.subtitle2}</span>
          </p>
        </div>
        <div className="md:text-xl text-base flex wrapper-footer-right">
          <div className="w-full wrapper-footer">
            <div className='pt-4 md:pt-0 w-full wrapper-footer-inner'>
              <p className="text-white text-ellipsis">
                Platforms
              </p>
              {footerLinks.Platforms.map((FooterLinks, i) => (
                <a
                  href={FooterLinks.link}
                  key={i}
                  className="text-white-forty block cursor-pointer my-4  text-base"
                >
                  {FooterLinks.title}
                </a>
              ))}
            </div>
            <div className='pt-4 md:pt-0 wrapper-footer-inner1'>
              <p className="text-white text-ellipsis">
                Company
              </p>
              {footerLinks.Company.map((FooterLinks, i) => (
                <a
                  href={FooterLinks.link}
                  key={i}
                  className="text-white-forty block cursor-pointer my-4 text-base"
                >
                  {FooterLinks.title}
                </a>
              ))}
            </div>
            <div className='pt-4 md:pt-0 wrapper-footer-inner resources-main'>
              <p className="text-white text-ellipsis">
                Resources
              </p>
              <div className='grid grid-cols-2 resources-wrapper'>
                {footerLinks.Resources.map((FooterLinks, i) => (
                  <a
                    href={FooterLinks.link}
                    key={i}
                    className="text-white-forty block cursor-pointer my-4 w-full whitespace-nowrap
                      text-base"
                  >
                    {FooterLinks.title}
                  </a>
                ))}
              </div>              
            </div>
            <div className='pt-4 md:pt-0 wrapper-footer-inner md:pl-3 pr-0'>
              <p className="text-white text-ellipsis">
                Contact
              </p>
              {footerLinks.Contact.map((FooterLinks, i) => (
                <a
                  href={FooterLinks.link}
                  key={i}
                  className="text-white-forty block cursor-pointer my-4 text-base"
                >
                  {FooterLinks.title}
                </a>
              ))}
            </div>
          </div>
        </div>
      </motion.div>
      <div className='block flex-wrap md:flex justify-between border-t border-black-border pt-5 wrapper'>
        <div className='flex md:flex-row flex-col !items-baseline'>
          <p className="text-white-sixty   mb-4">&copy;{footer.copy}</p>
          <a  target="_blank" href={require('../../assets/Images/Yarta-Privacy-Policy.pdf')} 
            rel="noreferrer"
            className="text-white-sixty cursor-pointer hover:text-white md:mx-5  mb-4">
            Privacy Policy
          </a>
          <a target="_blank" href={require('../../assets/Images/YartaT&Cs.pdf')} 
            rel="noreferrer" 
            className="text-white-sixty cursor-pointer hover:text-white mb-4">
            Terms of Conditions
          </a>
        </div>
        <div>
          <ul>
            <li className='inline-block mr-3'>
              <a href="https://www.linkedin.com/company/yarta">
                <img 
                  className='w-5 h-5' 
                  src={linkIn} 
                  alt="" />
              </a>
            </li>
            <li className='inline-block'>
              <a href="https://twitter.com/yarta_io">
                <img 
                  className='w-5 h-5' 
                  src={Twitter} 
                  alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
