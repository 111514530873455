import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 } from 'uuid';
const useStorage = (folder: string) => {
  const storage = getStorage();

  const upload = async (file: File) => {
    const extension = file.name.split('.').pop();
    if (!extension) return;
    const fileName = `${v4()}.${extension}`;
    const storageRef = ref(storage, `${folder}/${fileName}`);
    const snapshot = await uploadBytes(storageRef, file);
    return snapshot;
  };

  const getURL = async (fullPath: string) => {
    return await getDownloadURL(ref(storage, fullPath));
  };

  return {
    upload,
    getURL,
  };
};

export default useStorage;
