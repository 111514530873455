import DropdownTree from 'components/DropdownTree';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Closed from '../../assets/Icons/Closed';
import Hamburger from '../../assets/Icons/Hamburger';
import Logo from '../../assets/Icons/YartLogo';
import { NavLinks } from '../../utils/content';

function Header() {
  const [hoveredLink, setHoveredLink] = useState(-1);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const showLinkHandler = (i: number) => {
    setHoveredLink(i);
  };

  const hideLinkHandler = () => {
    setHoveredLink(-1);
  };

  const handleHamburgerToggle = () => {
    setIsHamburgerOpen(!isHamburgerOpen);
  };

  return (
    <header className='box-border top-0 w-full 2xl:h-24 py-6 bg-black-darkest'>
      <div className='flex items-center justify-between wrapper'>
        <a
          href='https://yarta.io/'
          rel='noreferrer'
          className='xl:w-auto lg:w-24'
        >
          <Logo width={115} height={30} />
          <p className='tracking-wide pt-1 pl-2 text-white '>
            trade on your terms
          </p>
        </a>
        <div onClick={handleHamburgerToggle} className='text-white lg:hidden'>
          <Hamburger />
        </div>
        {isHamburgerOpen && (
          <>
            <div className='fixed top-0 bottom-0 left-0 z-[30] w-full  pt-20 bg-black-dark h-screen overflow-auto'>
              <div
                onClick={handleHamburgerToggle}
                className='text-white lg:hidden relative z-[99] flex justify-end mt-[-50px] right-[15px]'
              >
                {isHamburgerOpen ? <Closed /> : <Hamburger />}
              </div>
              {NavLinks.map((link, i) => (
                <DropdownTree
                  links={link.subcategories}
                  header={link.title}
                  key={i}
                  handleHamburgerToggle={handleHamburgerToggle}
                />
              ))}
            </div>
          </>
        )}
        <div className='items-center hidden lg:flex flex-nowrap menu-wrapper'>
          {NavLinks.map((link, i) => (
            <div
              // eslint-disable-next-line @typescript-eslint/no-shadow
              onMouseEnter={() => showLinkHandler(i)}
              onMouseLeave={hideLinkHandler}
              className='relative menu-items py-12px 2xl:mx-2 xl:mx-1 mx-0 cursor-pointer 
              2xl:px-4 xl:px-3 px-2 rounded-md text-white navlink hover:bg-black-dark header-contact'
              key={i}
            >
              {link.title}
              {hoveredLink === i && (
                <div
                  className={`dropdown-set rounded-lg absolute left-0 top-[46px]
                    ${
                      link.subcategories
                        ? '!z-20 shadow-custom bg-black-dark w-72 border-black-border border-2'
                        : '!z-20 bg-black-dark w-72 top-12'
                    }
                  `}
                >
                  {link.subcategories?.map((category, key) => {
                    if (category.externalLink) {
                      return (
                        <div
                          key={key}
                          className='p-4 rounded-md hover:bg-black-darkest flex items-center'
                        >
                          <a
                            target='_blank'
                            href={category.externalLink}
                            rel='noreferrer'
                          >
                            <p className='font-bold text-white flex text-ellipsis'>
                              <span className='mr-3'>
                                <category.icon />
                              </span>
                              {category.title}
                            </p>
                            <p className='text-white-sixty text-s'>
                              {category.subtitle}
                            </p>
                          </a>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={key}
                          className='text-teams p-4 rounded-md hover:bg-black-darkest  flex items-center'
                        >
                          <Link to={category.link ? category.link : ''}>
                            <p className='font-bold text-white flex text-ellipsis'>
                              <span className='mr-3'>
                                <category.icon />
                              </span>
                              {category.title}
                            </p>
                            <p className='text-white-sixty text-s'>
                              {category.subtitle}
                            </p>
                          </Link>
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          ))}

          <Link
            className='relative py-12px xl:mx-2 cursor-pointer 2xl:px-4 xl:px-3 px-2 rounded-md text-white navlink
             hover:bg-black-dark'
            to='/contact'
          >
            Get in touch
          </Link>
          {/* <Link
            className='relative py-12px xl:mx-2 cursor-pointer 2xl:px-4 xl:px-3 px-2 rounded-md text-white navlink
             hover:bg-black-dark'
            to='/jobs_board'
          >
            Find a job!
          </Link> */}
          <a
            href='waiting_list'
            // href="http://yarta-21451866.hubspotpagebuilder.com/yartawaitinglist"
            target='_blank'
            className='py-2.5 xl:mx-2 cursor-pointer 2xl:px-4 xl:px-3 px-2 rounded-md text-white navlink
            hover:bg-black-dark'
            rel='noreferrer'
          >
            Login
          </a>
          <Link
            to='/waiting_list'
            className='hidden rounded-md  items-center 
            justify-center tracking-wide  2xl:ml-8  xl:ml-4 xl:px-3 ml-1 py-3 px-2 text-white font-bold
            lg:flex  bg-purple
            hover:bg-purple-hover'
            rel='noreferrer'
          >
            Join the Yarta Trade Waitlist
          </Link>
        </div>
      </div>
    </header>
  );
}

export default Header;
