/* eslint-disable max-len */
import { FC } from 'react';
import cube from 'assets/Images/Cube.png'

export const Cube: FC = () => {
  return (
    <img src={cube} alt=""
      className='w-5 h-5' />
  );
};

export default Cube;
