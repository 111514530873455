import dashboard from 'assets/Images/yartaBlock.png';

const Connect = () => {
  return (
    <>
      <div className="bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden">
        <div className="flex flex-col lg:flex-row wrapper h-full">
          <div className="md:w-full lg:w-1/2 flex-col text-center lg:text-left pt-10 lg:pt-0">
            <h2 className='text-lg md:text-3xl 2xl:pt-0 lg:pt-5 font-semibold'>Let’s Get Started</h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>Ready to Connect?</h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[580px] m-auto lg:m-0'>
              Contact our trade support team today to arrange your online
              one-on-one-demo - and see for yourself how the Yarta Trade Platform can help boost your trading.
            </p>
            <div className="flex w-full lg:w-4/6 justify-center lg:justify-start">
              <a 
                href="https://calendly.com/les-yarta/30min" 
                className='h-10 lg:my-10 mt-10 mb-20 rounded-md flex justify-center 
              items-center w-40 font-bold text-white bg-black-darkest btn-hover 
              overflow-hidden btn-hover relative'
                target="|_blank">
                <span className='absolute btn-effect transition-all duration-300 transi top-0 
              left-[-100%] bg-purple block h-full w-full'></span>
                <span className='relative btn-text'>Schedule a Demo</span>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[18%] lg:translate-y-[96px]
 h-fit flex align-bottom translate-x-[18%] translate-y-[0px]">
            <div className="rounded-tl-md border-black-border border-t-8 border-l-8">
              <img src={dashboard} className="xl:w-auto w-full"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
