export const selectStyles = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'white',
    boxShadow: state.isFocused ? null : null,
    backgroundColor: '#16151a',
    padding: 14,
    margin: 0,
    border: '1px solid #5e5bf9',
    '&:hover': {
      border: '1px solid #5e5bf9',
      backgroundColor: '#5e5bf9',
    },
  }),
  control: (provided: any) => ({
    ...provided,
    height: 63,
    width: '100%',
    color: 'white',
    padding: 11,
    border: '1px solid #5e5bf9',
    borderRadius: 10,
    fontSize: 18,
    backgroundColor: '#16151a',
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #5e5bf9',
    },
  }),
};
