import React from 'react';

const Surveys = () => {
  return (
    <>
      <div className='bg-black-darkest w-full text-white'>
        <div className='relative py-16 lg:py-32 xl:py-56 xl:pt-40'>
          <div className=' flex flex-col items-center justify-center text-center'>
            <div className='absolute inset-0 z-0 bg-black pointer-events-none'>
              <div className='bg-gradient w-full h-full bg-gradient-animation'></div>
              <div className='absolute inset-0 w-full bg-gradient-to-b from-black'></div>
              <div className='absolute inset-0 w-full bg-gradient-to-b from-black'></div>
            </div>
            <div className='relative wrapper'>
              <div className='max-w-[1090px] w-full text-left'>
                <h1 className='text-white font-bold lg:text-5xl  md:text-5xl sm:text-4xl text-3xl'>
                  The Weekly Yarta Agricultural Commodity Markets Survey
                </h1>
                <p className='text-white md:text-xl text-lg md:my-5 mt-5 mb-3'>
                  Fill out the weekly markets survey each Wednesday to Thursday
                  <br className='xl:block hidden' /> and receive the group results Friday morning.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>      
      <div>
        <div id='Survey-page'
          data-tf-widget='IXgqYCyX'
          data-tf-inline-on-mobile data-tf-medium='snippet' >
        </div>
      </div>
    </>
  );
};
export default Surveys;
