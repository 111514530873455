import BuildByTraders from 'components/BuildByTraders';
import TradeWithEase from 'components/TradeWithEase';
import { Content } from 'utils/content';
import chatVideo from 'assets/Images/video1.mp4';
import orderVideo from 'assets/Images/video2.mp4';
import Connect from 'components/Connect';
import HowItWork from 'components/HowItWork';


const GrainExchange = () => {
  const { grainExchange } = Content;

  return (
    <>
      <TradeWithEase />
      <HowItWork/>
      <BuildByTraders />
      <div className="bg-black-darkest lg:pt-20 pt-10 md:pb-28 pb-16">
        <div className="wrapper lg:!mb-32 !mb-20 justify-between sm:flex-row flex-col">
          <div className="xl:mr-5 md:my-10 xl:w-5/12 sm:w-1/2 w-full sm:text-left text-center sm:mb-0 mb-8">
            <p className="text-white md:text-5xl text-3xl">
              {grainExchange.tradeDirectly.title}
            </p>
            <p className="text-white-sixty md:text-2xl sm:text-xl text-base mt-4">
              {grainExchange.tradeDirectly.subtitle}
            </p>
            <p className='text-white-sixty text-2xl'>
              {grainExchange.tradeDirectly.description}
            </p>
          </div>
          <div className="xl:w-4/13 sm:w-[40%] w-full xl:mr-28  border-gradient relative">
            <video
              autoPlay
              src={chatVideo}
              width="100%"
              className="rounded-md"
              loop
              muted
            >
              Your Browser does not support videos
            </video>
          </div>
        </div>
        <div className="wrapper justify-between sm:flex-row flex-col-reverse">
          <div className="xl:w-4/13 sm:w-[40%] w-full  border-gradient relative">
            <video
              src={orderVideo}
              width="100%"
              className="rounded-md "
              autoPlay
              loop
              muted
            >
              Your Browser does not support videos
            </video>
          </div>
          <div className="xl:mr-28 xl:pl-10 md:my-10 xl:w-5/12 sm:w-1/2 w-full  sm:text-left text-center sm:mb-0 mb-8">
            <p className="text-white md:text-5xl text-3xl">
              {grainExchange.tradeCash.title}
            </p>
            <p className="text-white-sixty md:text-2xl sm:text-xl text-base mt-4">
              {grainExchange.tradeCash.subtitle}
            </p>
          </div>
        </div>
      </div>      
      <Connect/>
    </>
  );
};

export default GrainExchange;
