import Typewriter from 'typewriter-effect';
import { Content } from 'utils/content';
import dashboard from 'assets/Images/yartaBlock.png';
import { Link } from 'react-router-dom';

const TradeWithEase = () => {
  const { tradeWithEase } = Content;
  return (
    <div className="bg-black-darkest w-full h-full pb-10 text-white relative">
      <div className="flex flex-col lg:flex-row wrapper h-full ">
        <div
          className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
         flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  
        >
          <h1
            className="xl:text-6xl lg:text-5xl max-w-2xl
           sm:text-5xl text-3xl text-center lg:text-left sm:px-20 lg:px-0  text-white w-full  font-bold mb-7"
          >
            {tradeWithEase.title}
            <div className='type inline-block md:hidden'>
              <span className='text-purple'>{tradeWithEase.mobileTitle}</span>
            </div>
            <div className="hidden md:flex justify-center lg:justify-start items-center type">
              <span className="text-purple mr-3">with</span>
              <span className="text-pink xl:text-6xl lg:text-5xl sm:text-5xl text-3xl">
                <Typewriter
                  options={{
                    strings: ['ease', 'confidence', 'speed'],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
            </div>
          </h1>
          <div className="w-full flex justify-center lg:justify-start">
            <p
              className="text-white lg:text-xl text-center lg:text-left
             sm:w-4/5 w-full md:w-[90%] md:pr-10"
            >
              {tradeWithEase.desc}
            </p>
          </div>          
          <div className='flex gap-x-7 sm:justify-start justify-center flex-wrap gap-y-7 mt-10 lg:mb-0 mb-12'>
            <Link
              to=''
              target='_blank'
              className=' rounded-md flex justify-center 
            items-center  font-bold border border-purple
            relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
              rel='noreferrer'
            >
              <span
                className='absolute btn-effect
              transition-all duration-300 top-0 
            left-[-100%] bg-black block h-full w-full'
              ></span>
              <span className='relative btn-text p-3 tracking-wide text-white'>
                Start Trading
              </span>
            </Link>
            <Link
              to=''
              target='_blank'
              className='rounded-md flex justify-center 
            items-center  font-bold border border-purple
            relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
              rel='noreferrer'
            >
              <span
                className='absolute btn-effect
              transition-all duration-300 top-0 
            left-[-100%] bg-purple block h-full w-full'
              ></span>
              <span className='relative btn-text p-3 tracking-wide text-white'>
                Schedule a Demo
              </span>
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh lg:absolute top-0 h-fit">
        <div className="relative w-full h-full overflow-hidden">
          <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
            <img src={dashboard} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
            border-black-border border-t-8 border-l-8 ml-auto"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeWithEase;
