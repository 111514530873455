import AppRoutes from 'AppRoutes';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

function App() {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}
export default App;
