import useFirestore from 'fire-api/useFirestore';
import useStorage from 'fire-api/useStorage';
import { FirebaseError } from 'firebase/app';
import { EditorState } from 'react-draft-wysiwyg';
import { MutationOptions, useMutation } from 'react-query';

export type PostJobRequest = {
  image: File;
  company: string;
  shortDescription: string;
  description?: EditorState;
  position: string;
  title: string;
  applyLink: string;
  invoiceAddress: string;
  dollar: string;
  city: string;
  days: string;
  type: 'REMOTE' | 'ON_SITE';
  applyType: 'LINK' | 'EMAIL';
  apply: string;
  minCompensation: string;
  maxCompensation: string;
  tags: string[];
  skill: string[];
  highlightColor?: string;
  sticky?: string;
  isActive: boolean;
};

const useCreateJob = (config?: MutationOptions<any | undefined, any, any>) => {
  const { create } = useFirestore('jobs');
  const { upload, getURL } = useStorage('logos');

  return useMutation(
    async (payload: Partial<PostJobRequest>) => {
      if (!payload?.image) return;
      const logoRef = await upload(payload.image);
      const imageUrl = (await getURL(logoRef?.metadata.fullPath || '')) || '';
      const requestBody: any = {
        ...payload,
        description: '',
        image: '',
        logoUrl: imageUrl,
        isActive: false,
      };
      Object.keys(requestBody).forEach((key) =>
        requestBody[key] === undefined ? delete requestBody[key] : {}
      );
      const job = await create(requestBody);
      console.log(job);
      return job;
    },
    {
      ...config,
      onError(error: FirebaseError) {
        console.log(error);
        // if (error.code === "auth/user-not-found") {
        //   return "Wrong credentials.";
        // }
      },
    }
  );
};

export default useCreateJob;
