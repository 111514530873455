import { Menu, Transition } from '@headlessui/react';
import DropDown from 'assets/Images/down.png';
import Location from 'assets/Images/location.png';
import MoneyDollor from 'assets/Images/money-dollor.png';
import WhiteClock from 'assets/Images/white-clock.png';
import WhiteStar from 'assets/Images/white-star.png';
import { selectStyles } from 'components/Select/Select';
import DynamicBounds from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { Location as LocationOptions } from '../../PostJob/options';

type Props = {
  control: any;
  minSalary: number;
  maxSalary: number;
};

const Filterbar: React.FC<Props> = ({ control, maxSalary, minSalary }) => {
  // const [filter, setFilter] = useState<boolean>(true);
  // const handelToggle = () => {
  //   setFilter(!filter);
  // };

  console.log(minSalary, maxSalary);
  return (
    <div className='lg:block hidden'>
      <div className='w-full flex mb-14 xl:justify-center justify-evenly relative'>
        <div
          className={`bg-black-dark  xl:max-w-[818px] lg:max-w-[790px] w-full rounded-3xl p-2 flex
     justify-between`}
        >
          <Controller
            name='salary'
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className='flex items-center justify-start md:flex-nowrap flex-wrap'>
                <Menu
                  as='div'
                  className='relative inline-block text-left w-[170px] '
                >
                  <div>
                    <Menu.Button
                      className='inline-flex w-full justify-center items-center
            bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-purple rounded-3xl
            focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                    >
                      <img
                        className='mr-1 w-4'
                        src={MoneyDollor}
                        alt='dollar-sign'
                      />
                      Salary
                      <img className=' ml-1' src={DropDown} alt='arrow-down' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items
                      className='absolute xl:right-0 right-[-45px] mt-2 w-44 origin-top-right divide-y
             divide-gray-100 rounded-md
            bg-black-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                    >
                      <div className='px-1 py-1 '>
                        <div>
                          <div className='range-slider p-2 px-4'>
                            <p className='text-white text-[13px]'>
                              Min Salary:${value}/year
                            </p>
                            <DynamicBounds
                              min={minSalary}
                              max={maxSalary}
                              value={value}
                              onChange={(value) => onChange(value)}
                              className='mt-3 mb-2'
                            />
                          </div>
                        </div>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            )}
          />
          <div className='flex items-center justify-start md:flex-nowrap flex-wrap'>
            <Menu
              as='div'
              className='relative inline-block text-left w-[170px] ml-2'
            >
              <div>
                <Menu.Button
                  className='inline-flex w-full justify-center items-center 
            bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-purple rounded-3xl
            focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                >
                  <img
                    className='mr-1 w-[10px] '
                    src={Location}
                    alt='location'
                  />
                  Location
                  <img className='ml-1' src={DropDown} alt='arrow-down' />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items
                  className='absolute left-0 mt-2 w-[380px] origin-top-right divide-y
            divide-gray-100 
            bg-black-dark shadow-lg ring-1 ring-black  focus:outline-none'
                >
                  <div className='search-select-menu'>
                    <button
                      className='group w-full items-center px-2 py-2 text-sm flex justify-between text-white
                   cursor-pointer'
                    >
                      <Select
                        className='select-main search-job-location text-left basic-multi-select'
                        options={LocationOptions}
                        styles={selectStyles}
                        classNamePrefix='select'
                        name='colors'
                        isClearable
                        placeholder={<div>Country</div>}
                      />
                      <div className='flex'>
                        <p className='ml-3'>Remote</p>
                        <div className='switch-toggle remote-toggle mx-3 flex'>
                          <input type='checkbox' id='switch' />
                          <label htmlFor='switch'>Toggle</label>
                        </div>
                      </div>
                    </button>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          <div className='flex items-center justify-start md:flex-nowrap flex-wrap'>
            <Menu
              as='div'
              className='relative inline-block text-left w-[170px] ml-2'
            >
              <div>
                <Menu.Button
                  className='inline-flex w-full justify-center
            bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-purple rounded-3xl
            focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                >
                  <img className='mr-1 w-3 mt-[3px]' src={WhiteStar} alt='' />
                  Favorite
                </Menu.Button>
              </div>
            </Menu>
          </div>
          <div className='flex items-center justify-start md:flex-nowrap flex-wrap'>
            <Menu
              as='div'
              className='relative inline-block text-left w-[170px] ml-2'
            >
              <div>
                <Menu.Button
                  className='inline-flex w-full justify-center
            bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-purple rounded-3xl
            focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                >
                  <img className='mr-1 w-3 mt-1' src={WhiteClock} alt='' />
                  Type
                  <img className='mt-2 ml-1' src={DropDown} alt='' />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items
                  className='absolute right-0 mt-2 w-56 origin-top-right divide-y
             divide-gray-100 rounded-md
            bg-black-dark shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                >
                  <div className='px-1 py-1 '>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-purple text-white' : 'text-white'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Full-Time
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-purple text-white' : 'text-white'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Part-Time
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-purple text-white' : 'text-white'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Contract
                        </button>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${
                            active ? 'bg-purple text-white' : 'text-white'
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          Internship
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filterbar;
