import Header from '../Header/';
import Footer from '..//Footer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const  DefaultLayout = ({ children }: any) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default DefaultLayout;
