import React from 'react';
import HubspotForm from 'react-hubspot-form'

const Podcast = () => {
  return (
    <>
      <div>
        <div className='podcast-main-sec text-white flex
         justify-center bg-cover md:bg-60%'>
          <div className='wrapper !block text-center pb-9 px-4 z-10 pt-20'>
            <h2 className='md:text-6xl text-3xl leading-none font-bold'>Coming Soon: Yarta Markets Podcast</h2>
            <p className='md:text-2xl text-lg max-w-[900px] w-full mx-auto pt-8 sm:pb-8 pb-5'>A podcast designed to 
              democratise global commodity markets. We’ll be bringing some great guests in the first season to 
              decipher market news, trends and share some sprinkles of wisdom.
            </p>
            <div className="flex justify-center items-center gap-7 gap-y-3 flex-wrap">
              <a href="https://linktr.ee/yarta_io" className='mb-6 rounded-md flex justify-center
               items-center border-2 border-purple font-bold rvs-btn-hover                            
               overflow-hidden relative z-20'
              target='_blank' rel="noreferrer">
                <span className="absolute btn-effect transition-all duration-300 top-0 
                 left-0 bg-purple block h-full w-full"></span>
                <span className="relative btn-text p-3 tracking-wide">Subscribe</span>
              </a>
            </div>
            <div className="podcast-form max-w-[500px] w-full mx-auto mt-5">
              <p className='text-left md:text-2xl text-lg pb-3'>Sign up to be notified of new episodes.</p>
              <HubspotForm
                region= 'na1'
                portalId='21451866'
                formId='da8960cf-16ba-4cc1-9639-37dadfe0cc82'                
                onSubmit={() => console.log('Submit!')}
                loading={<div>Loading...</div>}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Podcast;
