/* eslint-disable max-len */
import { FC } from 'react';

export const People: FC = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8148 19.4691L14.0488 20.1119C14.2462 20.3471 14.5407 20.4786 14.8475 20.4685C15.1544 20.4585 15.4396 20.308 15.6212 20.0604L14.8148 19.4691ZM20.7087 13.1229C21.0353 12.6776 20.939 12.0518 20.4937 11.7252C20.0483 11.3986 19.4225 11.4948 19.0959 11.9402L20.7087 13.1229ZM12.8636 15.5887C12.5085 15.1657 11.8778 15.1106 11.4547 15.4656C11.0317 15.8207 10.9766 16.4514 11.3316 16.8744L12.8636 15.5887ZM15.6212 20.0604L20.7087 13.1229L19.0959 11.9402L14.0084 18.8777L15.6212 20.0604ZM11.3316 16.8744L14.0488 20.1119L15.5808 18.8262L12.8636 15.5887L11.3316 16.8744ZM27.3333 16.0003C27.3333 22.2596 22.2592 27.3337 16 27.3337V29.3337C23.3638 29.3337 29.3333 23.3641 29.3333 16.0003H27.3333ZM16 27.3337C9.74073 27.3337 4.66663 22.2596 4.66663 16.0003H2.66663C2.66663 23.3641 8.63616 29.3337 16 29.3337V27.3337ZM4.66663 16.0003C4.66663 9.7411 9.74073 4.66699 16 4.66699V2.66699C8.63616 2.66699 2.66663 8.63653 2.66663 16.0003H4.66663ZM16 4.66699C22.2592 4.66699 27.3333 9.7411 27.3333 16.0003H29.3333C29.3333 8.63653 23.3638 2.66699 16 2.66699V4.66699Z"
        fill="#31D158"
      />
    </svg>
  );
};

export default People;
