import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  WhereFilterOp,
} from 'firebase/firestore';
import firebase from '.';

export type FirestoreQueries = {
  property: string;
  operator: WhereFilterOp;
  value: any;
};

const useFirestore = (collectionName: string) => {
  const db = getFirestore(firebase.app);
  const collectionRef = collection(db, collectionName);

  // const search = async <T>(params: T) => {};

  const search = async <T>(queries: FirestoreQueries[]) => {
    console.log(queries);
    const q = query(
      collectionRef,
      ...queries.map(({ operator, property, value }) =>
        where(property, operator, value)
      )
    );
    const response = await getDocs(q);
    return {
      results: response.docs.map((item) => item.data()) as T[],
      total: response.docs.length,
    };
  };

  const getAll = async <T>() => {
    const response = await getDocs(collectionRef);
    console.log('getAll', response);
    console.log(response.docs.map((item) => item.data()));
    console.log(response.docs);
    return {
      results: response.docs.map((item) => item.data()) as T[],
      total: response.docs.length,
    };
  };

  const getById = async (docId: string) => {
    const response = await getDoc(doc(collectionRef, docId));
    console.log('getById', response);
    return response.data();
  };

  const create = async <T>(data: T) => {
    console.group('create', data);
    const docRef = await addDoc(collectionRef, {
      ...(data as any),
    });
    console.log('doc ref', docRef);
    const newDoc = await patch(docRef.id, { id: docRef.id });
    console.log('new doc', newDoc);
    console.groupEnd();
    return newDoc;
  };

  const remove = async (docId: string) => {
    const response = await deleteDoc(doc(collectionRef, docId));
    console.log('remove', response);
    return response;
  };

  const patch = async <T>(docId: string, data: Partial<T>) => {
    console.group('patch');
    const response = await updateDoc(
      doc(collectionRef, docId),
      data as DocumentData
    );
    console.log('response', response);
    const newDoc = await getById(docId);
    console.log('new doc', newDoc);
    console.groupEnd();
    return newDoc;
  };

  return {
    getAll,
    getById,
    create,
    remove,
    patch,
    search,
  };
};

export default useFirestore;
