export const TypePosition = [
  { value: 'Full time', label: 'Full time' },
  { value: 'Part time', label: 'Part time' },
  { value: 'Contract', label: 'Contract' },
  { value: 'Internship', label: 'Internship' },
];

export const Location = [{ value: 'No Option', label: 'No Option' }];

export const Tags = [
  { value: 'Bitcoin', label: 'Bitcoin' },
  { value: 'Community Manager', label: 'Community Manager' },
  { value: 'Customer Success', label: 'Customer Success' },
  { value: 'DeFi', label: 'DeFi' },
  { value: 'Entry-Level', label: 'Entry-Level' },
];

export const MinimumAnnualSalary = [
  { value: '0', label: '0' },
  { value: '5000', label: '5000' },
  { value: '10000', label: '10000' },
  { value: '15000', label: '15000' },
  { value: '20000', label: '20000' },
  { value: '25000', label: '25000' },
  { value: '30000', label: '30000' },
  { value: '35000', label: '35000' },
  { value: '40000', label: '40000' },
];

export const MaximumAnnualSalary = [
  { value: '0', label: '0' },
  { value: '5000', label: '5000' },
  { value: '10000', label: '10000' },
  { value: '15000', label: '15000' },
  { value: '20000', label: '20000' },
  { value: '25000', label: '25000' },
  { value: '30000', label: '30000' },
  { value: '35000', label: '35000' },
  { value: '40000', label: '40000' },
];
