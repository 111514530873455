import Clock from 'assets/Images/clock.png';
import MoneyDollor from 'assets/Images/money-dollor.png';
import Star from 'assets/Images/star.png';
import { JobsResponse } from 'fire-api/jobs/useJobs';
import 'rc-slider/assets/index.css';
import { Link } from 'react-router-dom';
interface Props extends JobsResponse {}

const JobItem: React.FC<Props> = ({
  id,
  days,
  tags,
  city,
  logoUrl,
  position,
  title,
  minCompensation,
  maxCompensation,
}) => {
  console.log(logoUrl);
  return (
    <div className='overflow-auto'>
      <div
        className='flex text-white min-w-[540px] items-center justify-between w-full overflow-x-auto md:py-2
         py-4 hover:opacity-[0.7]
        duration-300 cursor-pointer'
      >
        <div className='flex items-center md:max-w-[616px] max-w-[248px] w-full'>
          <div className='max-w-12'>
            <img className='object-cover w-16 h-16' src={logoUrl} alt='' />
          </div>
          <div className='ml-8'>
            <h5
              className='md:text-[17px] text-[12px] font-bold md:max-w-[250px]
                max-w-[153px] w-full'
            >
              {position}
            </h5>
            <p className='md:text-sm text-[10px] mt-1'>{title}</p>
            <div className='flex mt-1'>
              <img
                className='md:block hidden w-5 h-6 mr-1'
                src={MoneyDollor}
                alt=''
              />
              <p className='md:text-sm text-[10px]'>
                {minCompensation} - {maxCompensation}
              </p>
            </div>
          </div>
        </div>
        <div className='xl:max-w-[240px] md:max-w-[176px] max-w-[95px] w-full'>
          <p className='md:text-sm text-[10px]'>{city}</p>
        </div>
        <div className='flex ml-6 md:max-w-[300px] max-w-[245px] w-full'>
          {tags?.map((tag, index) => (
            <Link
              to='#0'
              className='text-[12px] p-[7px] mt-[9px] mr-[9px] border-[1px]
      border-white rounded'
              key={index}
            >
              {tag}
            </Link>
          ))}
        </div>
        <div className='md:max-w-[100px] max-w-[65px] w-full flex'>
          <img className='h-5 w-5' src={Clock} alt='' />
          <p className='ml-3 text-[13px]'>{days}</p>
        </div>
        <div className='max-w-[70px] w-full text-start'>
          <Link
            to={`/apply_job/${id}`}
            className='text-[15px] py-[10px] px-[12px] bg-purple rounded'
          >
            Apply
          </Link>
        </div>
        <div
          className='tooltips ml-4 cursor-pointer mr-2 2xl:w-[90px] xl:w-[200px]
    md:w-[210px] w-[59px] relative first:pt-4'
        >
          <img className='w-[26px] h-[26px]' src={Star} alt='' />
          <p
            className='tooltip p-2 h-[32px] opacity-0 inline-block transition-all 
        absolute -top-[32px] -left-[44px] text-[12px] 
      bg-black-dark leading-4 rounded before:border-black-dark before:content-[""]
        before:bottom-[-20%] border-solid before:left-[45%] before:absolute 
        before:rotate-[45deg] '
          >
            Add To Favorits
          </p>
        </div>
      </div>
    </div>
  );
};

export default JobItem;
