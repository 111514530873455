/* eslint-disable max-len */
import { FC } from 'react';

export const Money: FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 40 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 16.8C17.02 16.8 14.6 18.42 14.6 20.4C14.6 22.38 17.02 24 20 24C22.98 24 25.4 25.62 25.4 27.6C25.4 29.58 22.98 31.2 20 31.2M20 16.8V31.2M20 16.8C22 16.8 23.74 17.52 24.68 18.6L20 16.8ZM20 16.8V15M20 31.2V33M20 31.2C18 31.2 16.26 30.48 15.3199 29.4L20 31.2ZM38 24C38 26.36 37.54 28.7 36.62 30.88C35.72 33.06 34.38 35.04 32.72 36.72C31.04 38.4 29.06 39.72 26.88 40.62C24.7 41.54 22.36 42 20 42C17.64 42 15.3 41.54 13.12 40.62C10.94 39.7 8.96 38.38 7.28 36.72C5.6 35.04 4.28 33.06 3.38 30.88C2.46 28.7 2 26.36 2 24C2 19.22 3.9 14.64 7.28 11.28C10.64 7.9 15.22 6 20 6C24.78 6 29.36 7.9 32.72 11.28C36.1 14.64 38 19.22 38 24Z"
        stroke="#F0AEFD"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Money;
