import BuildByFarmer from 'components/BuildByFarmer';
import { Content } from 'utils/content';
import orderVideo from 'assets/Images/Grains-Overview.png';
import { Link } from 'react-router-dom';
// import dashboard from 'assets/Images/yartaBlock.png';
// import Agriculturegrow from '../../assets/Images/agricultureimg.png';
import Challenge from '../../assets/Images/challenge.png';
import Global from '../../assets/Images/globleagricult.png';
import TradeCommoditiesImg from 'assets/Images/homeagri.png';


const Resources = () => {
  const { resources } = Content;

  return (
    <>
      <div className='bg-black-darkestF pt-10 md:pb-28 pb-8'>        
        <div className="w-full h-full pb-10 text-white relative">
          <div className="flex flex-col lg:flex-row wrapper h-full ">
            <div
              className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
              flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  >
              <p className='text-white md:text-6xl text-3xl lg:text-left text-center font-bold tracking-tight
              leading-10 max-w-[570px] w-full'>
                {resources.tradeDirectly.titleBeforeSpan}
                <span className='text-gradient'>
                  {' '}
                  {resources.tradeDirectly.titleSpan}{' '}
                </span>
                {resources.tradeDirectly.title}
              </p>
              <p className='text-white lg:text-xl mt-7 lg:text-left text-center max-w-[530px] w-full mx-auto sm:mx-0'>
                {resources.tradeDirectly.subtitle}
              </p>
              <p className='text-white lg:text-xl lg:text-left text-center mt-7 max-w-[508px] w-full mx-auto sm:mx-0'>
                {resources.tradeDirectly.description}
                <span className='font-bold'>
                  {' '}
                  {resources.tradeDirectly.descriptionspan}{' '}
                </span>
              </p>    
                    
              <div className='gap-x-5 sm:justify-start justify-center mt-10 lg:mb-0 mb-12'>
                <Link
                  to='/chat'
                  target='_blank'
                  className=' rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-black block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Subscribe to Market Chats
                  </span>
                </Link>
               
              </div>
            </div>
          </div>
          <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh
          lg:absolute top-0 h-fit">
            <div className="relative w-full h-full overflow-hidden">
              <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
                <img src={TradeCommoditiesImg} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
                border-black-border border-t-8 border-l-8 ml-auto"></img>
              </div>
            </div>
          </div>
        </div>  
       
        <BuildByFarmer />
        <div className="wrapper md:!mb-16 2xl:!mb-32 !mb-20 justify-between md:gap-7 
          gap-9 md:flex-row flex-col">
          <div className=" md:my-10 xl:w-[38%]  w-full md:text-left text-center sm:mb-0">
            <p className="text-white md:text-5xl text-3xl">
              {resources.tradeDirect.title}
            </p>
            <p className="text-white-sixty md:text-2xl sm:text-xl text-base mt-4">
              {resources.tradeDirect.subtitle}
            </p>
            <div>
              <Link to='' className='flex items-center justify-center md:justify-start gap-2 mt-4
                ease-out hover:ease-in group   transition duration-300'>
                <div  className=" text-white md:text-xl text-base">Coming soon </div>                
                {/* <svg width="24" height="24" 
                  viewBox="0 0 24 24" fill="none" 
                  className='w-4 group-hover:translate-x-2 transition duration-300'
                  xmlns="http://www.w3.org/2000/svg">
                  <path d="M24.0607 13.0607C24.6464 12.4749 24.6464 11.5251 24.0607 10.9393L14.5147
                  1.3934C13.9289 0.807611 12.9792 0.807611 12.3934 1.3934C11.8076 1.97919 11.8076
                  2.92893 12.3934 3.51472L20.8787 12L12.3934 20.4853C11.8076 21.0711 11.8076 22.0208
                  12.3934 22.6066C12.9792 23.1924 13.9289 23.1924 14.5147 22.6066L24.0607 13.0607ZM0
                  13.5H23V10.5H0V13.5Z" fill="white" 
                  fillOpacity="1" className='w-8'/>
                </svg> */}
              </Link>
            </div>            
          </div>
          <div className="border-gradient lg:translate-x-[15%] md:translate-x-[6%] lg:mt-28 md:mt-16 ">
            <img src={Challenge} 
              alt="" className=' w-full max-w-[820px]'/>
          </div>
        </div> 
        <div className="wrapper lg:!mb-32 !mb-20 justify-between md:gap-7 gap-9 md:flex-row flex-col relative ">
          <div className="border-gradient new-img md:order-1 order-2">
            <img src={orderVideo} className="rounded-md"></img>
          </div>
          <div className="md:order-2 order-1 md:my-10 xl:w-[50%] md:w-1/2 w-full md:text-left text-center">
            <div className='xl:pl-10 md:my-10 xl:w-full sm:w-full w-full xl:max-w-[77%] md:text-left 
              text-center'>
              <p className='text-white md:text-5xl text-3xl'>
                {resources.tradeCash.title}
              </p>
              <p className='text-white-sixty md:text-2xl mx-auto md:ml-0 sm:text-xl text-base mt-4
                max-w-[543px] w-full'>
                {resources.tradeCash.subtitle}
              </p>
            </div>    
          </div>
        </div> 
      </div>     
      <div className='bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden'>
        <div className='flex flex-col lg:flex-row wrapper h-full'>
          <div className='md:w-full lg:w-1/2 flex-col text-center lg:text-left lg:py-24 2xl:py-60F py-12 pb-8'>
            <h2 className='text-lg md:text-3xl font-semibold'>
              Let’s Get Started{' '}
            </h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>
              Connect with global agriculture employers
            </h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[580px] m-auto lg:m-0'>
              Check out our global agriculture jobs board with a focus on remote jobs.
            </p>
            <div className='flex w-full lg:w-4/6 justify-center lg:justify-start'>
              <Link
                to=''
                target='_blank'
                className='sm:mt-10 mt-4 rounded-md flex justify-center 
                items-center  font-bold bg-darkest border-2 border-none
                relative overflow-hidden btn-hover max-w-[200px] w-full bg-black-dark  sm:mx-0 mx-auto'
                rel='noreferrer'>
                <span
                  className='absolute btn-effect
                transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='relative btn-text p-3 tracking-wider text-white'>
                  View Available Jobs
                </span>
              </Link>
            </div>
          </div>
          <div
            className='w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[38%]  lg:translate-y-[110px]
            h-fit  align-bottom  translate-x-[31%] translate-y-[18px]'>
            <div className='lg:mt-0 md:pt-20 pt-8F'>
              <img src={Global} className="xl:w-auto w-full rounded-2xl"></img>
            </div>
          </div>
        </div>
      </div>  
    </>
  );
};
export default Resources;
