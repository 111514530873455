import Footer from 'components/Footer';
import 'rc-slider/assets/index.css';
import { useParams } from 'react-router-dom';
import Header from '../../components/JobHeader/index';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const ApplyJob = () => {
  const { jobId } = useParams();
  console.log(jobId);
  return (
    <>
      <Header />
      <div
        id='wrapper'
        className='h-screen'
        data-tf-widget='woH2PFKX'
        data-tf-inline-on-mobile
        data-tf-medium='snippet'
        data-tf-hidden={`jobid=${jobId}`}
      ></div>
      <script src='//embed.typeform.com/next/embed.js' />
      <Footer />
    </>
  );
};

export default ApplyJob;
