/* eslint-disable max-len */
import { FC } from 'react';
import Sueveys from '../Images/survey.png';

export const Suevey: FC = () => {
  return (
    <>
      <img src={Sueveys} alt='' 
        className='w-6'/>
    </>
  );
};
export default Suevey;
