import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../../assets/Icons/YartLogo';

function index() {
  return (
    <header className='box-border top-0 w-full 2xl:h-24 py-6 bg-black-darkest z-100'>
      <div className='flex items-center justify-between wrapper'>
        <Link to="/">
          <Logo width={115} height={30} />
        </Link>
        <div>
          <Link 
            to='/post-job'
            className='h-10 rounded-md flex justify-center 
            items-center w-40 font-bold bg-darkest border-2 border-purple ml-3
            relative overflow-hidden btn-hover'
          >
            <span className="absolute btn-effect transition-all duration-300 top-0 
             left-[-100%] bg-purple block h-full w-full"></span>
            <span className="relative btn-text text-white" >Post Job</span>
          </Link>
        </div>
      </div>
    </header>
  )
}

export default index