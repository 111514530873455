import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const FamersAndTraders = () => {
  const { farmerandtraders } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();

  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };

  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);

  return (
    <div className="bg-black-darkest w-full md:pt-[108px] pt-10  text-white"> 
      <motion.div
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
        className="wrapper  xl:px-20 lg:px-20  flex-col items-center lg:items-start"
      >
        <h2 className="text-3xl md:text-5xl sm:px-20 px-10 text-center max-w-[820px] w-full
        md:text-left text-white font-bold mb-8">
          Built{' '}
          <span className="text-pink  block md:inline text-gradient">
            by farmers and traders, for farmers and traders
          </span>
        </h2>
        <div className='flex w-full justify-center flex-wrap built-traders md:mb-16 mb-8 px-4'>
          <div className='xl:rounded-lg sm:w-5/12 w-full mr-4 flex sm:mr-5 items-center mb-5'>
            <div className='text-white-sixty text-base'>
              We’re building a high-performance commodity trading, research, 
              and logistics platform - all in one convenient platform ecosystem.
            </div>
          </div>
          <div className='xl:rounded-lg sm:w-5/12 w-full mr-4 flex sm:mr-5 items-center mb-5'>
            <div className='text-white-sixty text-base sm:pl-2'>
              The Yarta Market Intel Platform brings everything together in one place to help you make
              informed hedging and trading decisions.
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center flex-wrap built-traders">
          {farmerandtraders.card.map((card, i) => (
            <div
              key={i}
              className="rounded-lg lg:w-3/5 xl:w-5/12  w-full sm:w-4/6  mr-4  px-5 flex 
                 bg-black-dark my-2 xl:mr-5 py-6 items-center"
            >
              <div className="h-10 mr-4 flex justify-center lg:justify-start">
                <card.iconName />
              </div>
              <div>
                <p className="xl:text-lg 2xl:text-xl text-lg  font-bold text-white-light">
                  {card.title}
                </p>
                <p className=" mt-1 text-white-sixty">{card.desc}</p>                
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default FamersAndTraders;
