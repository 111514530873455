import { forwardRef, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLInputElement> {
  error?: boolean;
  helperText?: string;
  type?: string;
}

const Input = forwardRef<HTMLInputElement, Props>(
  ({ error, type = 'text', helperText, className, ...props }, ref) => {
    return (
      <div className='w-full'>
        <input
          {...props}
          ref={ref}
          type={type}
          className={`md:px-4 px-3 md:py-[16px] py-[12px] text-lg bg-[#16151A] 
w-full ${
            error ? 'border-red' : 'border-purple'
          } rounded-[10px] md:placeholder:text-lg placeholder:text-base ${className}`}
        />
        {helperText && (
          <p
            className={`text-[#5E5BF9] text-sm mt-[4px] ${
              error ? 'text-red' : ''
            }`}
          >
            {helperText}
          </p>
        )}
      </div>
    );
  }
);

export default Input;
