import useFirestore, { FirestoreQueries } from 'fire-api/useFirestore';
import { useQuery } from 'react-query';
import { PostJobRequest } from './useCreateJob';

export interface UseJobsParams {
  salary?: number;
  tags?: string[];
}
export interface JobsResponse extends PostJobRequest {
  id: string;
  logoUrl: string;
}

const useJobs = (params?: UseJobsParams) => {
  const { search } = useFirestore('jobs');

  return useQuery(['jobs', params], async () => {
    console.log(params);
    const queries: FirestoreQueries[] = [
      {
        property: 'isActive',
        operator: '==',
        value: true,
      },
    ];

    const data = await search<JobsResponse>(queries);
    const tags = data.results.map((job) => job.tags).flat(1);
    const uniqueTags = tags.filter((tag, index) => tags.indexOf(tag) === index);
    const averageSalary = data.results.reduce((acc, job) => {
      return (
        (acc +
          (parseInt(job.minCompensation) + parseInt(job.maxCompensation)) / 2) /
        2
      );
    }, 0);
    const minStartValue = data?.results?.[0]?.minCompensation
      ? parseInt(data?.results?.[0]?.minCompensation)
      : 0;
    const minSalary = data.results.reduce(
      (acc, item) =>
        acc > parseInt(item.minCompensation)
          ? parseInt(item.minCompensation)
          : acc,
      minStartValue
    );
    const maxSalary = data.results.reduce(
      (acc, item) =>
        acc < parseInt(item.maxCompensation)
          ? parseInt(item.maxCompensation)
          : acc,
      0
    );

    const filteredResults = data.results.filter((job) => {
      if (params?.salary) {
        const min = parseInt(job.minCompensation);
        const max = parseInt(job.maxCompensation);
        return min <= params.salary && max >= params.salary;
      }
      if (params?.tags) {
        return job.tags.every((tag) => params?.tags?.includes(tag));
      }
      return true;
    });

    const total = filteredResults.length;

    return {
      results: filteredResults,
      total: total,
      tags: uniqueTags,
      averageSalary,
      minSalary,
      maxSalary,
    };
  });
};

export default useJobs;
