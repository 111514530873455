import Footer from 'components/Footer';
import { selectStyles } from 'components/Select/Select';
import useJobs, { UseJobsParams } from 'fire-api/jobs/useJobs';
import 'rc-slider/assets/index.css';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import MoneyDollor from '../../assets/Images/money-dollor.png';
import SearchIcon from '../../assets/Images/search-icon.png';
import Header from '../../components/JobHeader/index';
import { Location as LocationOptions } from '../PostJob/options';
import Filterbar from './components/Filterbar';
import JobItem from './components/JobItem';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const JobsBoard = () => {
  const { control, watch } = useForm<UseJobsParams>({
    defaultValues: {},
  });
  const [filters, setFilters] = useState<UseJobsParams>();
  const { data: jobs } = useJobs(filters);
  const filtersLoaded = jobs?.minSalary && jobs?.maxSalary;

  useEffect(() => {
    const subscription = watch((value) => setFilters(value as UseJobsParams));
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Header />
      <div className='py-28 text-white'>
        <div className='wrapper !block text-center'>
          <div className=''>
            <h2 className='md:text-5xl text-3xl text-white font-bold text-center w-full'>
              Top Agriculture Job all over the{' '}
              <span className='text-purple'> World</span>
            </h2>
            <p
              className='text-white lg:text-xl text-center
               w-full  md:pr-10 my-12'
            >
              Browse 614 jobs at 341 Agriculture Projects
            </p>
            <Controller
              name='tags'
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className='cursor-pointer max-w-[1000px] w-full flex mx-auto flex-wrap justify-center'>
                  {jobs?.tags?.map((tag, index) => (
                    <div
                      key={index}
                      className={`text-[12px] sm:p-[13px] p-[7px] my-[5px] sm:mx-[8px] mx-1 border-[1px]
                      ${
                        value?.includes(tag)
                          ? 'border-purple'
                          : 'border-white-sixty'
                      } rounded hover:border-white-sixty duration-300`}
                      onClick={() => {
                        if (value?.includes(tag)) {
                          onChange(value.filter((item: any) => item !== tag));
                        } else {
                          onChange([...(value || []), tag]);
                        }
                      }}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              )}
            />
            <div
              className='mt-8 pt-4 border-l-0 border-r-0 border-b-0 border-t-purple border-2 
            max-w-[390px] w-full mx-auto'
            >
              <div className='relative text-left'>
                <Select
                  className='select-main search-job'
                  options={LocationOptions}
                  styles={selectStyles}
                  getOptionLabel={(option) => option.label}
                  isClearable
                  placeholder={<div>Tag or Location</div>}
                />
                <img
                  src={SearchIcon}
                  className='absolute top-5 w-4 left-11'
                  alt=''
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='max-w-[1733px] px-6 w-full mx-auto md:mt-14 mt-0'>
        <h2 className='md:text-5xl text-3xl text-white font-bold text-center w-full'>
          All Jobs
        </h2>
        <div className='text-white flex md:flex-row flex-col justify-between items-center max-w-[450px] w-full mx-auto'>
          <p
            className=' lg:text-xl text-center
               md:pr-10 md:my-12 mb-2 mt-4'
          >
            {jobs?.total} Jobs found
          </p>
          <p
            className='flex lg:text-xl text-center
                md:my-12 mt-1 mb-12'
          >
            <img
              src={MoneyDollor}
              className='w-4 h-5 mt-1 mr-2'
              alt='dollar-sign'
            />{' '}
            Average Salary ${jobs?.averageSalary.toFixed(0)}
          </p>
        </div>
        {filtersLoaded && (
          <Filterbar
            control={control}
            minSalary={jobs?.minSalary || 0}
            maxSalary={jobs?.maxSalary || 0}
          />
        )}
        {jobs?.results?.map((item, index) => (
          <JobItem {...item} key={index} />
        ))}
      </div>
      {/* top web3 cities section start */}
      <div className='md:mt-20 mt-10 bg-black py-20'>
        <div>
          <h2 className='md:text-3xl text-xl text-center text-white pb-16 font-bold'>
            Top Agriculture Job Locations
          </h2>
        </div>
        <div className='grid gap-x-14 lg:grid-cols-4 sm:grid-cols-2 text-white md:mx-[50px] mx-8'>
          <div>
            <ul>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Texas</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  62
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Remote Agriculture Jobs in Australia</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  46
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in New York</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  36
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Singapore</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  19
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Sydney</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  17
                </span>
              </li>
            </ul>
          </div>
          <div className='sm:mt-0 mt-4'>
            <ul>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base '>
                  <Link to=''>Agriculture Jobs in Ohio</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  14
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Remote Agriculture Jobs in USA</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  12
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Des Moines</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  11
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Geneva</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  9
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Melbourne</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  8
                </span>
              </li>
            </ul>
          </div>
          <div className='lg:mt-0 sm:mt-10 mt-4'>
            <ul>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Iowa</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  7
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Remote Agriculture Jobs in India</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  7
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in San Francisco</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  5
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Paris</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  5
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Brisbane</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  4
                </span>
              </li>
            </ul>
          </div>
          <div className='lg:mt-0 sm:mt-10 mt-4'>
            <ul>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in California</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  4
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Remote Agriculture Jobs in France</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  3
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Chicago</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  3
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Toronto</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  3
                </span>
              </li>
              <li className='flex justify-between pb-[30px]'>
                <p className='md:text-lg text-base'>
                  <Link to=''>Agriculture Jobs in Adelaide</Link>
                </p>
                <span className='text-[9px] px-[7px] py-1 border border-[#8C7D7D] h-[22px]'>
                  3
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* top web3 cities section end */}
      <Footer />
    </>
  );
};

export default JobsBoard;
