import FamersAndTraders from 'components/FamersAndTraders';
import { Content } from 'utils/content';
import { Link } from 'react-router-dom';
import Satellite from '../../assets/Images/Satellite.png';
import News from '../../assets/Images/News.png';
import Newsfreetrial from '../../assets/Images/free-trial.png';
import MarketIntelHero from '../../assets/Images/market-intel-hero-img.png';
// import arrowimg from '../../assets/Images/trade-arrow.png';
// import dashboard from 'assets/Images/yartaBlock.png';

const MarketIntelPlatform = () => {
  const { marketplatform } = Content;
  return (
    <>
      <div className='bg-black-darkest  pt-10'>       
        <div className="bg-black-darkest w-full h-full pb-10 text-white relative">
          <div className="flex flex-col lg:flex-row wrapper h-full ">
            <div
              className="lg:w-1/2 w-full flex items-center justify-center xl:justify-start lg:items-start
              flex-col lg:py-24 md:pt-24 pt-12 lg:h-80vh"  >
              <p className='text-white md:text-6xl text-3xl lg:text-left text-center font-bold tracking-tight
              leading-10 max-w-[556px] w-full'>
                {marketplatform.tradeDirectly.titleBeforeSpan}
                <span className='text-gradient'>
                  {' '}
                  {marketplatform.tradeDirectly.titleSpan}{' '}
                </span>
              </p>
              <p className='text-white lg:text-xl mt-7 lg:text-left text-center max-w-[590px] w-full mx-auto sm:mx-0'>
                {marketplatform.tradeDirectly.subtitle}
              </p>
                    
              <div className='flex gap-x-5 sm:justify-start justify-center flex-wrap gap-y-7 mt-10 lg:mb-0 mb-12'>
                <Link
                  to='/market_intel_waiting_list'
                  target='_blank'
                  className=' rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-purple  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-black block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Join the Yarta Market Intel Waitlist
                  </span>
                </Link>
                <Link
                  to='/request_demo'
                  className='rounded-md flex justify-center 
                items-center  font-bold border border-purple
                relative overflow-hidden btn-hover  bg-black  sm:mx-0 '
                  rel='noreferrer'
                >
                  <span
                    className='absolute btn-effect
                  transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                  ></span>
                  <span className='relative btn-text lg:p-3 px-2 py-3 tracking-wide text-white'>
                    Request a Demo
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="lg:w-1/2-2rem w-full lg:rounded-tl-md right-0 pink-gradient lg:h-80vh
          lg:absolute top-0 h-fit">
            <div className="relative w-full h-full overflow-hidden">
              <div className="lg:absolute top-0 right-0 w-full h-full flex items-center">
                <img src={MarketIntelHero} className="2xl-s:max-w-xl xl:w-auto w-full rounded-tl-md 
                border-black-border border-t-8 border-l-8 ml-auto"></img>
              </div>
            </div>
          </div>
        </div>
        <FamersAndTraders />        
      </div>
      {/* time you traded start */}
      <section>
        <div className='bg-black-darkest w-full h-full text-white relative overflow-hidden lg:mt-28 mt-9'>
          <div className='flex flex-col lg:flex-row wrapper h-full xl:-mt-8 !w-[84%] lg:gap-28 gap-y-7
            justify-between'>
            <div className='flex-col text-center lg:text-left lg:py-24 2xl:py-60 py-8 pb-8'>
              <h2 className='text-3xl md:text-[60px] pt-4 max-w-[636px] w-full tracking-[-0.02em] leading-none'>
                Powerful data and analytics
              </h2>
              <p className='pt-6 md:text-2xl text-base font-medium max-w-[480px] m-auto lg:m-0 text-[#7B7A7D]'>
                Tap in to growing list of fundamental and alternative data, proprietary and
                third party research and analytics.
              </p>
              {/* <div className='flex w-full lg:w-4/6 justify-center lg:justify-start'>
                <Link to='' className=' text-xl flex items-center gap-2 md:mt-6
                mt-4 group transition duration-300 ease-out
                hover:ease-in max-w-[230px] w-full'>
                  Find out more
                  <img src={arrowimg} alt=''
                    className='w-6 group-hover:translate-x-2 transition duration-300' />
                </Link>
              </div> */}
            </div>
            <div
              className=' 
              h-fit  align-bottom  '>
              <div className='lg:mt-0 md:mt-10'>
                <img src={Satellite} className='max-w-[748px] w-full' />
              </div>
            </div>            
          </div>
        </div>
      </section>
      <section>
        <div className='bg-black-darkest w-full h-full text-white relative overflow-hidden lg-mt-0 mt-5'>
          <div className='flex flex-col-reverse lg:flex-row lg:gap-28 gap-y-7 wrapper lg-pt-0 pt-10 h-full
            2xl:-mt-24 lg:-mt-7
            !w-[84%] gap-28 justify-between'>
            <div
              className='w-full 
              h-fit  align-bottom '>
              <div className='lg:mb-0 mb-20'>
                <img src={News} />
              </div>
            </div>
            <div className='md:w-full flex-col text-center lg:text-left lg:py-24 2xl:py-44 py-8 pb-8'>
              <h2 className='text-3xl md:text-[60px]  max-w-[636px] mx-auto lg:mx-0 w-full tracking-[-0.02em]
                leading-none'>
                Custom live news streaming so you never miss a beat
              </h2>
              <p className='pt-6 md:text-2xl text-base font-medium max-w-[480px] m-auto lg:m-0 text-[#7B7A7D]'>
                Giving you the edge on your competition, our alt data tools such as vessel tracking, global 
                news sentiment, and volume along with search metrics give you insight into what is really driving the market.
              </p>
              {/* <div className='flex w-full lg:w-4/6 justify-center lg:justify-start'>
                <Link to='' className=' text-xl flex items-center gap-2 md:mt-6
                 mt-4 group transition duration-300 ease-out
                 hover:ease-in max-w-[230px] w-full justify-center lg:justify-start'>
                  Find out more
                  <img src={arrowimg} alt=''
                    className='w-6 group-hover:translate-x-2 transition duration-300' />
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="bg-black-darkest w-full h-full text-white relative bg-gradient overflow-hidden">
        <div className="flex flex-col lg:flex-row wrapper h-full">
          <div className="md:w-full lg:w-1/2 flex-col text-center lg:text-left pt-10 xl:pt-0">
            <h2 className='text-lg md:text-3xl font-semibold'>Stay In The Loop</h2>
            <h2 className='text-3xl md:text-5xl font-semibold pt-4 tracking-[-0.02em]'>Coming Soon</h2>
            <p className='pt-9 md:text-xl text-base font-medium max-w-[563px] m-auto lg:m-0'>
              We’re currently beta testing the Yarta Market Intel platform and will be releasing this very soon.
              We’re excited to share it with you. 
            </p>
            <p className='pt-4 md:text-xl text-base font-medium max-w-[582px] m-auto lg:m-0'>
              To receive updates on the release, signup below.              
            </p>
            <div className="flex w-full  justify-center lg:justify-start lg:mb-5">
              <Link
                to='/waiting_list'
                target='_blank'
                className='sm:mt-10 mt-4 rounded-md flex justify-center 
                items-center  font-bold bg-darkest border-2 border-none
                relative overflow-hidden btn-hover max-w-[190px] w-full bg-black-dark  sm:mx-0 mx-auto'
                rel='noreferrer'>
                <span
                  className='absolute btn-effect
                transition-all duration-300 top-0 
                left-[-100%] bg-purple block h-full w-full'
                ></span>
                <span className='xl:text-xl text-base relative btn-text p-3  text-white'>
                  Join the Waitlist
                </span>
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-[50vw] min-w-[50vw] lg:translate-x-[36%] xl:translate-y-[38px]
        h-fit flex align-bottom translate-x-[18%] lg:translate-y-[74px] translate-y-[18px]">
            <div className="md:pt-20 ">
              <img src={Newsfreetrial} className="xl:w-auto w-full rounded-tl-md rounded-bl-none"></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketIntelPlatform;
