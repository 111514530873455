/* eslint-disable max-len */
import { FC } from 'react';
import Mics from '../Images/mic.png';

export const Mic: FC = () => {
  return (
    <>
      <img src={Mics} alt='' 
        className='w-6 h-6'/>
    </>
  );
};

export default Mic;
