import React from 'react'
// import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const CentralEcosystem = () => {
  // const { centralEcosystem } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();
  
  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };
  
  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);
  return (
    <div>
      <div className="bg-black-darkest w-full  text-white">        
        <motion.div
          ref={ref}
          variants={variant}
          initial="hidden"
          animate={control}
          className="wrapper  xl:px-20 lg:px-20  flex-col !items-center lg:items-start md:mt-20 mt-10">
          <h2 className="text-3xl md:text-5xl  text-center  2xl:max-w-[1075px] xl:max-w-[718px]
            md:max-w-[683px] max-w-[485px] w-full
          md:text-left text-white font-bold mb-8">
            A  {' '}
            <span className="text-pink  block md:inline text-gradient">
              central eco-system
            </span> <br className='md:block hidden'/> built for the commodity
            <span className='text-gradient'> pro.</span> 
          </h2>        
          <div className='flex 2xl:gap-24 xl:gap-16 sm:gap-16 gap-10 xl:justify-start
           md:mb-16 mb-7 2xl:max-w-[1075px] w-full xl:max-w-[718px] md:max-w-[683px] max-w-[485px]'>
            <div className='xl:rounded-lg md:w-[40%] sm:w-[65%] w-full flex sm:mr-5 items-center mb-5 '>
              <p className='text-white-sixty text-base'>
                We’re building a high-performance commodity trading, research and logistics
                platform - all in one convenient ecosystem.
              </p>
            </div>          
            <div className='flex flex-col text-white-sixty text-base text-lightpurple font-semibold'>
              <span>Research.</span>
              <span>Connect.</span>
              <span>Trade.</span>
              <span>Move.</span>
            </div>
          </div>
          {/* <div className="flex w-full justify-center flex-wrap built-traders">
            {centralEcosystem.card.map((card, i) => (
              <div
                key={i}
                className="rounded-lg lg:w-3/5 xl:w-5/12  w-full sm:w-4/6  mr-4  px-6 flex 
                   bg-black-dark my-2 xl:mr-5 py-8 items-center"
              >
                <div className=" mr-4 flex justify-center lg:justify-start">
                  <img src={card.img} alt="" 
                    className='min-w-[30px]'/>
                </div>
                <div>
                  <p className="xl:text-lg 2xl:text-xl text-lg  font-bold text-white-light">
                    {card.title}
                  </p>
                  <p className=" mt-1 text-white-sixty">{card.desc}</p>
                </div>
              </div>
            ))}
          </div> */}
        </motion.div>
      </div>
    </div>
  )
}

export default CentralEcosystem