import About from 'components/About';
import Layout from 'components/Layouts';
import Frontend from 'components/position/Frontend';
import Fullstack from 'components/position/Fullstack';
import ApplyJob from 'pages/ApplyJob/ApplyJob';
import Blockplatform from 'pages/Blockplatform/Blockplatform';
import Careers from 'pages/Careers/Careers';
import Chats from 'pages/chats/Chats';
import Daily from 'pages/Daily/Daily';
import Events from 'pages/Events/Events';
import SuggestEvents from 'pages/Events/SuggestEvents';
import GrainExchange from 'pages/GrainExchange/GrainExchange';
import HelpCenter from 'pages/HelpCenter/HelpCenter';
import Home from 'pages/Home/Home';
import NewHome from 'pages/Home/NewHome';
import JobsBoard from 'pages/JobsBoard/JobsBoard';
import Logistics from 'pages/Logistics/Logistics';
import LogisticsWaitlist from 'pages/LogisticsWaitlist/LogisticsWaitlist';
import MarketIntelPlatform from 'pages/MarketIntelPlatform/MarketIntelPlatform';
import Marketintelwaitinglist from 'pages/MarketIntelWaitinglist/Marketintelwaitinglist';
import Podcast from 'pages/Podcast/Podcast';
import PostJob from 'pages/PostJob/PostJob';
import RequestDemo from 'pages/RequestDemo/RequestDemo';
import Resources from 'pages/Resources/Resources';
import Surveys from 'pages/surveys/Surveys';
import Waitinglist from 'pages/waitinglist/Waitinglist';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, Routes } from 'react-router-dom';
import Marketquotesboard from 'pages/MarketQuotesBoard/Marketquotesboard';
import QuantitativeResearcher from 'components/position/QuantitativeResearche';
type HelmetProps = React.ComponentProps<typeof Helmet>;

const HelmetWithChildren: React.FC<
  HelmetProps & { children?: React.ReactNode }
> = ({ children, ...rest }) => {
  return (
    // @ts-ignore
    <HelmetProvider>
      {/* @ts-ignore */}
      <Helmet {...rest}>{children}</Helmet>
    </HelmetProvider>
  );
};
const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/' />
              <title>
                Yarta - Agricultural Commodity Trading, Logistics Management,
                and Market Intelligence Platform
              </title>
              <meta
                name='description'
                content='Yarta is a central platform for agricultural commodity trading; stay up to date with market intelligence, improve your logistics management, and use our grain exchange for buying and selling grain with ease.'
              />
              <meta
                name='keywords'
                content='agricultural commodity trading, Selling Grain, Buying grain'
              />
            </HelmetWithChildren>

            <Layout>
              <NewHome />
            </Layout>
          </>
        }
      />
      <Route
        path='/home'
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path='/about'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/about' />
              <title> About Us - Yarta </title>
              <meta
                name='description'
                content="We're building a high-performance commodity trading, research and logistics platform that makes it easy for you to get the most out of your commodities."
              />
            </HelmetWithChildren>

            <Layout>
              <About />
            </Layout>
          </>
        }
      />
      <Route
        path='/careers'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/careers' />
              <title> Careers - Yarta </title>
              <meta
                name='description'
                content='At Yarta, we’re making it easier, faster and simpler for traders and farmer to trade, finance and hedge their commodities. Come build with us, and help change the game.'
              />
            </HelmetWithChildren>

            <Layout>
              <Careers />{' '}
            </Layout>
          </>
        }
      />
      <Route
        path='/careers/frontend'
        element={
          <Layout>
            {' '}
            <Frontend />
          </Layout>
        }
      />
      <Route
        path='/careers/Fullstack'
        element={
          <Layout>
            {' '}
            <Fullstack />{' '}
          </Layout>
        }
      />
      <Route
        path='/careers/Quantitative-Researcher'
        element={
          <Layout>
            {' '}
            <QuantitativeResearcher />{' '}
          </Layout>
        }
      />
      <Route
        path='/help-center'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/help-center' />
              <title> Help Center - Yarta </title>
              <meta
                name='description'
                content="We're building a high-performance commodity trading, research and logistics platform that makes it easy for you to get the most out of your commodities."
              />
            </HelmetWithChildren>

            <Layout>
              <HelpCenter index={1} />
            </Layout>
          </>
        }
      />
      <Route
        path='/contact'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/contact' />
              <title> Get in Touch with US - Yarta </title>
              <meta
                name='description'
                content='If you have any questions about our products or services, or you’d like help opening an account, please reach out. Get in touch with the team via email or phone.'
              />
            </HelmetWithChildren>

            <Layout>
              <HelpCenter index={5} />
            </Layout>
          </>
        }
      />
      <Route
        path='/waiting_list'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/waiting_list' />
              <title> Waitlist - Yarta Trade platform </title>
              <meta
                name='description'
                content='We’ve built a trading platform designed for the pro. Join the waitlist to receive updates on our upcoming launch as we finalize beta testing.'
              />
            </HelmetWithChildren>

            <Waitinglist />
          </>
        }
      />
      <Route path='/request_demo' element={<RequestDemo />} />
      <Route path='/jobs_board' element={<JobsBoard />} />
      <Route path='/apply_job/:jobId' element={<ApplyJob />} />
      <Route path='/post-job' element={<PostJob />} />
      <Route
        path='/logistics'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/logistics' />
              <title> Livestock Logistics Management - Yarta </title>
              <meta
                name='description'
                content='Looking for a secure and private space to manage your livestock logistics? Yarta offers secure and private livestock management software and, more!'
              />
              <meta
                name='keywords'
                content='livestock logistics, Logistics Management, livestock management software, Logistics Management'
              />
            </HelmetWithChildren>

            <Layout>
              <Logistics />
            </Layout>
          </>
        }
      />
      <Route
        path='/market_intel'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/market_intel' />
              <title>
                Chart Wheat, Corn, Soybean Futures Prices - Market Intel
                Platform - Yarta
              </title>
              <meta
                name='description'
                content='Yarta Market Intel Platform is the ultimate commodity trading tool for research. It provides everything you need to make trading decisions.'
              />
              <meta
                name='keywords'
                content='wheat futures price, corn futures price, soybean futures price, commodity trading research, commodity trading tool, Market Intel Platform'
              />
            </HelmetWithChildren>

            <Layout>
              <MarketIntelPlatform />
            </Layout>
          </>
        }
      />
      <Route
        path='/resources'
        element={
          <Layout>
            <Resources />
          </Layout>
        }
      />
      <Route
        path='/grain-exchange'
        element={
          <Layout>
            <GrainExchange />
          </Layout>
        }
      />
      <Route
        path='/market_intel_waiting_list'
        element={<Marketintelwaitinglist />}
      />
      <Route path='/logistics_waiting_list' element={<LogisticsWaitlist />} />
      <Route
        path='/trade'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/trade' />
              <title>
                {' '}
                Online Grain and Livestock Trading | Grain and Livestock
                Exchange Prices - Yarta
              </title>
              <meta
                name='description'
                content='Get the best grain and livestock exchange prices, bids and offers for Australian grain at Yarta. Our platform provides data, analytics, trading, and more. '
              />
              <meta
                name='keywords'
                content='grain exchange, Australian grain'
              />
            </HelmetWithChildren>

            <Layout>
              <Blockplatform />
            </Layout>
          </>
        }
      />
      <Route
        path='/suggest_events'
        element={
          <Layout>
            <SuggestEvents />
          </Layout>
        }
      />
      <Route
        path='/events'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/events' />
              <title> Agriculture Trade Events - Yarta </title>
              <meta
                name='description'
                content='Check out our list of the top agriculture trade events, shows, and conferences in 2022! From trade shows to educational seminars, training, and, more!'
              />
              <meta
                name='keywords'
                content='agriculture events, Trade Events, agriculture trade shows'
              />
            </HelmetWithChildren>

            <Layout>
              <Events />
            </Layout>
          </>
        }
      />
      <Route
        path='/survey'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/survey' />
              <title> Commodity Market Analysis and Survey - Yarta </title>
              <meta
                name='description'
                content='Yarta offers agricultural commodity markets analysis survey that allows farmers and other interested parties to stay up-to-date on the latest market trends.'
              />
              <meta
                name='keywords'
                content='Commodity Market Analysis, Market Analysis Survey, commodity markets analysis'
              />
            </HelmetWithChildren>

            <Layout>
              <Surveys />
            </Layout>
          </>
        }
      />
      <Route
        path='/chat'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/chat' />
              <title> Chat | Automated Market Updates - Yarta </title>
              <meta
                name='description'
                content=' Want free automated market updates in chat? Check out our public chats on Whatsapp below. Stay informed with our market intel chats.'
              />
            </HelmetWithChildren>

            <Layout>
              <Chats />
            </Layout>
          </>
        }
      />
      <Route
        path='/daily_email'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/daily_email' />
              <title> Daily Market Update Email - Yarta </title>
              <meta
                name='description'
                content='Daily Market Update is a daily intelligence briefing from Yarta about Commodity Trading. A quick daily digest of market updates delivered to your inbox daily.'
              />
              <meta
                name='keywords'
                content='daily market update email, Daily Market Update, daily intelligence briefing'
              />
            </HelmetWithChildren>

            <Layout>
              <Daily />
            </Layout>
          </>
        }
      />
      <Route
        path='/podcast'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/podcast' />
              <title> Commodity Trading Podcast - Yarta </title>
              <meta
                name='description'
                content='Looking for a commodity trading podcast that covers everything about commodity trading? Covering global grain and livestock market news.'
              />
              <meta
                name='keywords'
                content='Commodity Trading Podcast, commodities podcast, Trading Podcast'
              />
            </HelmetWithChildren>

            <Layout>
              <Podcast />
            </Layout>
          </>
        }
      />
      <Route
        path='/market_quotes_board'
        element={
          <>
            <HelmetWithChildren>
              <link rel='stylesheet' href='https://yarta.io/market_quotes_board' />
              <title> Commodity Trading Market Quotes Board - Yarta </title>
              <meta 
                name="description" 
                content="Yarta Market Quotes Board is the perfect way to track global futures market 
                prices. You can easily find prices for grains, livestock, energy, metals and, more!"/>
              <meta 
                name="keywords" 
                content="commodity trading quotes, Market Quotes Board, Trading Market Quotes, Market Quotes Board"/>
            </HelmetWithChildren>

            <Layout>
              <Marketquotesboard />
            </Layout>
          </>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
