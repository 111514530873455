import BuildByTraders from 'components/BuildByTraders';
import Connect from 'components/Connect';
import OurMission from 'components/OurMission';
import StartTradingToday from 'components/StartTradingToday';
import TradeWithEase from 'components/TradeWithEase';

const Home = () => {
  return (
    <>
      <div className='home-page'>
        <TradeWithEase />
        <BuildByTraders />
        <OurMission />
        <StartTradingToday />
        <Connect/>
      </div>
    </>
  );
};

export default Home;
