import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA7KEqyZz3Nj6WdfnZraUBZloTFMmmz18c',
  authDomain: 'yarta-jobs-15b50.firebaseapp.com',
  projectId: 'yarta-jobs-15b50',
  storageBucket: 'yarta-jobs-15b50.appspot.com',
  messagingSenderId: '936972339628',
  appId: '1:936972339628:web:3ff7af4542cbd711675209',
  measurementId: 'G-HC0H1NC86S',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const firebase = {
  app,
  analytics,
};

export const auth = getAuth();

export default firebase;
