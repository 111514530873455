const SuggestEvents = () => {
  return (
    <>
      <div
        id="wrapper" 
        className="wrapper-suggest"
        data-tf-widget="dX1sRpIy"
        data-tf-inline-on-mobile
        data-tf-medium="snippet"
      ></div>
    </>
  );
};
export default SuggestEvents;
