import { Closed } from 'assets/Icons';
import Footer from 'components/Footer';
import Input from 'components/Input/Input';
import Header from 'components/JobHeader/index';
import { selectStyles } from 'components/Select/Select';
import useCreateJob, { PostJobRequest } from 'fire-api/jobs/useCreateJob';
import { useMemo, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
// import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ThunderIcon from '../../assets/Images/thunder_icon.svg';
import {
  Location,
  MaximumAnnualSalary,
  MinimumAnnualSalary,
  Tags,
  TypePosition,
} from './options';

const PostJob = () => {
  const maxCharacters = 120;
  const [message, setMessage] = useState('');
  const {
    register,
    control,
    formState: { errors },
    watch,
    setValue: setFormValue,
    handleSubmit,
  } = useForm<Partial<PostJobRequest>>();
  const [value, setValue] = useState('');
  const { mutate: postJob } = useCreateJob();
  const apply = watch().applyType === 'EMAIL';
  const image = watch().image;

  const [customClolor, setCustomClolor] = useState<boolean>();
  const handlerCustomClolor = () => {
    setCustomClolor(!customClolor);
  };

  const onSubmit = (data: Partial<PostJobRequest>) => {
    console.log(data);
    postJob(data, {
      onSuccess: () => {
        setMessage('Job created successfully!');
        setTimeout(() => setMessage(''), 3000);
      },
    });
  };

  const imageUrl = useMemo(() => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return undefined;
  }, [image]);

  return (
    <>
      <Header />
      <div className='lg:w-[82%] w-[90%] mx-auto mt-8 post-web-job-main'>
        <h1 className='md:text-[40px] text-[32px] font-bold text-center text-white md:mb-8 mb-6'>
          Post a Agriculture Job
        </h1>
        <div className='border-gradient-one mb-11'>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='lg:p-16 md:p-10 p-4'
          >
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Company Name:
              </label>
              <Input
                {...register('company', { required: 'Field is required.' })}
                type='text'
                placeholder='e.g. Coinbase, Binance, Palmswap'
                error={!!errors.company?.message}
                helperText={errors.company?.message}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Job Title:
              </label>
              <Input
                {...register('title')}
                type='text'
                placeholder='e.g. Community Manager, Rust Developer'
                error={!!errors.title?.message}
                helperText={errors.title?.message}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Type of Position:
              </label>
              <Controller
                control={control}
                name='position'
                render={({ field }) => (
                  <Select
                    className='select-main'
                    options={TypePosition}
                    styles={selectStyles}
                    placeholder={<div>select one...</div>}
                    onChange={(option) => field.onChange(option?.value)}
                    value={TypePosition.find(
                      (option) => option.value === field.value
                    )}
                  />
                )}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Location:
              </label>
              <div className='grid gap-x-8 grid-cols-2 items-center remote-redio-line'>
                <Controller
                  control={control}
                  name='city'
                  render={({ field }) => (
                    <Select
                      className='select-main'
                      options={Location}
                      styles={selectStyles}
                      placeholder={
                        <div>
                          type in the city your company is located or/and toggle
                          remote
                        </div>
                      }
                      onChange={(option) => field.onChange(option?.value)}
                      value={TypePosition.find(
                        (option) => option.value === field.value
                      )}
                    />
                  )}
                />
                <div className='flex items-center remote-redio-main'>
                  <div className='mb-0 text-lg md:text-xl font-bold inline-block'>
                    Remote{' '}
                  </div>
                  <div className='switch-toggle mx-3 flex'>
                    <Controller
                      control={control}
                      name='type'
                      render={({ field }) => (
                        <input
                          type='checkbox'
                          id='switch'
                          onChange={(e) =>
                            field.onChange(
                              e.target.checked ? 'REMOTE' : 'ON_SITE'
                            )
                          }
                          checked={field?.value === 'REMOTE'}
                        />
                      )}
                    />
                    <label htmlFor='switch'>Toggle</label>
                  </div>
                </div>
              </div>
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Job Description:
              </label>
              <Controller
                control={control}
                name='description'
                render={({ field }) => (
                  <Editor
                    wrapperClassName='job-description-wrapper'
                    editorClassName='job-description-editor'
                    toolbar={{
                      options: ['inline', 'link', 'history'],
                    }}
                    onEditorStateChange={(editorState) =>
                      field.onChange(editorState)
                    }
                    editorState={field.value}
                  />
                )}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Short Company Description :
                <span className='ml-1'>
                  {value.length}/{maxCharacters}
                </span>
              </label>
              <input
                {...register('shortDescription')}
                className='md:px-4 px-3 md:py-[16px] py-[12px] text-lg bg-[#16151A] 
              w-full border-purple rounded-[10px] md:placeholder:text-lg placeholder:text-base'
                type='text'
                value={value}
                placeholder='Will be displayed on the jobview page (max 120 letters)'
                onChange={(e) => {
                  const val = e.target.value;
                  setValue(val.substring(0, maxCharacters));
                }}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Tags:
                <span className='text-xs ml-1'>(Select up to 6 tags.)</span>
              </label>
              <Controller
                control={control}
                name='tags'
                render={({ field }) => (
                  <Select
                    className='select-main'
                    isMulti
                    options={Tags}
                    styles={selectStyles}
                    placeholder={<div>select up to 6 tags...</div>}
                    onChange={(options) =>
                      field.onChange(options?.map((o) => o.value))
                    }
                    value={Tags.filter((option) =>
                      field.value?.includes(option.value)
                    )}
                  />
                )}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Compensation (USD):
              </label>
              <div className='grid gap-x-8 md:gap-y-0 gap-y-4 md:grid-cols-2 items-center'>
                <Controller
                  control={control}
                  name='minCompensation'
                  render={({ field }) => (
                    <Select
                      className='select-main'
                      options={MinimumAnnualSalary}
                      styles={selectStyles}
                      placeholder={<div>Minimum annual salary</div>}
                      onChange={(option) => field.onChange(option?.value)}
                      value={MinimumAnnualSalary.find(
                        (option) => option.value === field.value
                      )}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='maxCompensation'
                  render={({ field }) => (
                    <Select
                      className='select-main'
                      options={MaximumAnnualSalary}
                      styles={selectStyles}
                      placeholder={<div>Maximum annual salary</div>}
                      onChange={(option) => field.onChange(option?.value)}
                      value={MaximumAnnualSalary.find(
                        (option) => option.value === field.value
                      )}
                    />
                  )}
                />
              </div>
            </div>
            <div className='form-fields mb-6'>
              <div className='w-full block mb-3 text-lg md:text-xl font-bold'>
                How to apply
              </div>
              <Controller
                control={control}
                name='applyType'
                render={({ field }) => (
                  <div className='md:flex w-full mb-3'>
                    <div className='flex items-center radio-root-btn'>
                      <input
                        className={`px-4 py-[16px] text-lg bg-[#16151A] border-purple 
                  rounded-[10px] ${apply ? 'checked' : ''}`}
                        type='radio'
                        id='test1'
                        name='radio-group'
                        value='EMAIL'
                        checked={field.value === 'EMAIL'}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <label
                        className='inline-block text-xl font-bold mr-6 ml-2'
                        htmlFor='test1'
                      >
                        Apply by email
                      </label>
                      <div className='check'></div>
                    </div>
                    <div className='flex items-center radio-root-btn'>
                      <input
                        className={`px-4 py-[16px] text-lg bg-[#16151A] border-purple
                  rounded-[10px] md:placeholder:text-lg placeholder:text-base ${
                    !apply ? 'checked' : ''
                  }`}
                        type='radio'
                        id='test2'
                        name='radio-group'
                        value='LINK'
                        checked={field.value === 'LINK'}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <label
                        className='inline-block text-xl font-bold mr-6 ml-2'
                        htmlFor='test2'
                      >
                        Apply by website
                      </label>
                      <div className='check'></div>
                    </div>
                  </div>
                )}
              />

              <input
                {...register('applyLink')}
                className='md:px-4 px-3 md:py-[16px] py-[12px] text-lg bg-[#16151A] 
              w-full border-purple rounded-[10px]'
                type='text'
                placeholder={`${
                  apply ? 'e.g. apply@company.com' : 'e.g. https://company.com'
                }`}
              />
            </div>
            <div className='form-fields mb-6'>
              <label className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Invoice Address:
              </label>
              <input
                {...register('invoiceAddress')}
                className='md:px-4 px-3 md:py-[16px] py-[12px] text-lg bg-[#16151A] 
              w-full border-purple rounded-[10px] md:placeholder:text-lg placeholder:text-base'
                type='text'
                placeholder='Company’s full name, full address and VAT number....'
              />
            </div>
            <div className='form-fields mb-6'>
              <div className='w-full block mb-3 text-lg md:text-xl font-bold'>
                Add a logo:
              </div>
              <div
                className='file-upload-wrapper'
                data-text='Select your file!'
              >
                {imageUrl ? (
                  <div className='w-full'>
                    <div
                      className='w-32 object-cover m-auto flex justify-end'
                      onClick={() => setFormValue('image', undefined)}
                    >
                      <Closed />
                    </div>
                    <img
                      src={imageUrl}
                      alt='preview'
                      className='w-32 h-32 object-cover m-auto'
                    />
                  </div>
                ) : (
                  <>
                    <label htmlFor='file-upload'>
                      <h4 className='md:text-2xl text-lg mb-3'>
                        Select a file or drag and drop here
                      </h4>
                      <p className='mb-4 md:text-lg text-xs text-white-sixty'>
                        JPG, PNG or PDF, file size no more than 10MB
                      </p>
                      <span className='choose-file cursor-pointer'>
                        Choose File
                      </span>
                    </label>
                    <Controller
                      control={control}
                      name='image'
                      render={({ field }) => (
                        <input
                          name='file-upload-field'
                          type='file'
                          accept='image/*'
                          className='file-upload-field'
                          id='file-upload'
                          onChange={(e) => {
                            field.onChange(e.target?.files?.[0]);
                          }}
                        />
                      )}
                    />
                  </>
                )}
              </div>
              <div>
                <div className='mt-5 mb-3 md:flex items-center'>
                  <span className='text-[15px]'>Stick post on top for:</span>
                  {/* <button
                    className='flex items-center md:ml-4 ml-0 border border-purple
                    bg-purple rounded-lg py-2 px-4 font-bold md:mt-0 mt-3 md:text-lg text-xs'
                  >
                    Compare
                    <img className='ml-2 w-4' src={ArrowRight} alt='' />
                  </button> */}
                </div>
                <div className='w-full'>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='no-sticky'
                      name='stick-post'
                      onChange={() => setFormValue('sticky', '')}
                    />
                    <label
                      className='inline-block text-xl font-bold mr-6 ml-2'
                      htmlFor='no-sticky'
                    >
                      No sticky
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='hours24'
                      name='stick-post'
                      onChange={() => setFormValue('sticky', '24h')}
                    />
                    <label
                      className='inline-block text-xl font-bold mr-6 ml-2'
                      htmlFor='hours24'
                    >
                      24 hours: $99
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='days7'
                      name='stick-post'
                      onChange={() => setFormValue('sticky', '7d')}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2'
                      htmlFor='days7'
                    >
                      7 days: $149
                      <div className='ml-2 flex text-xs py-2 px-2 items-center border border-purple rounded'>
                        <span className='block whitespace-nowrap mr-2'>
                          6X more views
                        </span>
                        <img src={ThunderIcon} alt='' />
                      </div>
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='days14'
                      name='stick-post'
                      onChange={() => setFormValue('sticky', '14d')}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2'
                      htmlFor='days14'
                    >
                      14 days: $199
                      <div className='ml-2 flex text-xs py-2 px-2 items-center border border-purple rounded'>
                        <span className='block whitespace-nowrap mr-2'>
                          12X more views
                        </span>
                        <img src={ThunderIcon} alt='' />
                      </div>
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='days30'
                      name='stick-post'
                      onChange={() => setFormValue('sticky', '1m')}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2'
                      htmlFor='days30'
                    >
                      30 days: $249
                      <div className='ml-2 flex text-xs py-2 px-2 items-center border border-purple rounded'>
                        <span className='block whitespace-nowrap mr-2'>
                          24X more views
                        </span>
                        <img src={ThunderIcon} alt='' />
                      </div>
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='mt-5 mb-3 md:flex items-center'>
                    <span className='text-[15px]'>
                      Highlight your post with colors:
                    </span>
                    {/* <button
                      className='flex items-center md:ml-4 ml-0 border border-purple
                    bg-purple rounded-lg py-2 px-4 font-bold md:mt-0 mt-3 md:text-lg text-xs'
                    >
                      Compare
                      <img className='ml-2 w-4' src={ArrowRight} alt='' />
                    </button> */}
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='nocolor'
                      name='color'
                      onChange={(e) => {
                        setCustomClolor(false);
                        e.target.checked && setFormValue('highlightColor', '');
                      }}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2'
                      htmlFor='nocolor'
                    >
                      No color:Free
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='standard-color'
                      name='color'
                      onChange={(e) => {
                        setCustomClolor(false);
                        e.target.checked &&
                          setFormValue('highlightColor', '#5E5BF9');
                      }}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2'
                      htmlFor='standard-color'
                    >
                      Standard color:$99
                      <div className='ml-2 flex text-xs py-2 px-2 items-center border border-purple rounded'>
                        <span className='block whitespace-nowrap mr-2'>
                          2X more views
                        </span>
                        <img src={ThunderIcon} alt='' />
                      </div>
                    </label>
                    <div className='check'></div>
                  </div>
                  <div className='flex w-full items-center radio-root-btn stick-post-main'>
                    <input
                      className='px-4 py-[16px] text-lg bg-[#16151A] border-purple rounded-[10px]'
                      type='radio'
                      id='custom-color'
                      name='color'
                      onChange={() => {
                        setCustomClolor(true);
                      }}
                    />
                    <label
                      className='flex flex-wrap items-center text-xl font-bold mr-6 ml-2 md:mb-0 mb-2'
                      htmlFor='custom-color'
                    >
                      Custom color: $199
                      {customClolor ? (
                        <input
                          className='ml-3 mr-1 md:mb-0 mb-2'
                          type='color'
                          onChange={(e) => {
                            setFormValue('highlightColor', e.target.value);
                          }}
                        />
                      ) : (
                        ''
                      )}
                      <div
                        className='ml-2 mr-3 flex text-xs py-2 px-2 items-center border border-purple rounded 
                      md:mb-0 mb-2'
                      >
                        <span className='block whitespace-nowrap mr-2'>
                          4X more views
                        </span>
                        <img src={ThunderIcon} alt='' />
                      </div>
                      {/* <button
                        className='flex items-center border border-purple
                      bg-purple rounded py-2 px-3 font-bold text-xs'
                      >
                        Compare
                        <img className='ml-2 w-4' src={ArrowRight} alt='' />
                      </button> */}
                    </label>
                    <div className='check'></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='post-job-btn-main text-center md:pt-20 pt-8 md:pb-10 pb-8'>
              {!!message && (
                <div className='mb-2'>
                  <p className='text-green'>{message}</p>
                </div>
              )}
              <button
                className='border border-purple max-w-[50%] w-full
              bg-purple rounded-lg md:py-3 md:px-4 py-2 px-3 md:text-xl text-lg mx-auto text-center
              hover:bg-white hover:text-purple transition-all duration-300
              font-bold'
                type='submit'
              >
                Post a Job
              </button>
              <Link
                to='#'
                className='w-full inline-block md:mt-7 mt-4 underline text-lg'
              >
                Save as draft
              </Link>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PostJob;
