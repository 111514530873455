import { Dispatch, FC, SetStateAction } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ArrowDown from 'assets/Icons/ArrowDown';

type Props = {
  i: number;
  expanded: boolean | number;
  setExpanded: Dispatch<SetStateAction<number | false>> ;
  header: string;
  subtitle: string[];
  span?: {
    beforeSpan: string;
    span: string;
    afterSpan: string;
  };
};

const FaqDropdown: FC<Props> = ({
  header,
  subtitle,
  span,
  i,
  expanded,
  setExpanded,
}) => {
  const isOpen = i === expanded;

  // const handleToggle = () => setIsOpen((prev) => !prev);

  return (
    <div className="border-b border-black-border md:w-auto w-full">
      <motion.header
        initial={false}
        // animate={{ backgroundColor: isOpen ? '#16151A' : '#1F1E25' }}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        className=" cursor-pointer  md:w-750px p-4 rounded-md  md:my-4 my-1 text-white  "
        onClick={() => setExpanded(isOpen ? false : i)}
      >
        <div className="flex  items-center justify-between">
          <p className="md:text-xl text-lg  mr-10">{header}</p>
          <ArrowDown rotate={isOpen} width={20} />
        </div>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            className=" overflow-hidden"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.98 }, open: { scale: 1 } }}
              transition={{ duration: 0.5 }}
            >
              {subtitle.map((item, key) => (
                <>
                  <div
                    key={key}
                    className="!z-20 text-white-sixty md:mb-5 mb-2 md:w-750px md:px-6 px-3 rounded-lg "
                  >
                    {item}
                  </div>
                  {span && (
                    <div className="md:mb-5 mb-2 md:w-750px md:px-6 px-3 text-white-sixty">
                      {span?.beforeSpan}
                      <a href="/" className="text-white mx-1">
                        {span?.span}
                      </a>
                      {span?.afterSpan}
                    </div>
                  )}
                </>
              ))}
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
};

export default FaqDropdown;
