import React from 'react'
import { Content } from '../../utils/content';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import arrowimg from '../../assets/Images/trade-arrow.png';

const TradePhysical = () => {
  const { tradePhysical } = Content;
  const control = useAnimation();
  const [ref, inView] = useInView();
  
  const variant = {
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { y: 50, opacity: 0 },
  };
  
  useEffect(() => {
    if (inView) {
      control.start('visible');
    } else {
      control.start('hidden');
    }
  }, [control, inView]);
  return (
    <div>
      <div className="bg-black-darkest w-full text-white md:mt-6 mt-16">
        <motion.div
          ref={ref}
          variants={variant}
          initial="hidden"
          animate={control}
          className="wrapper xl:px-20 lg:px-20 flex-col items-center lg:items-start"
        >
          <div className="flex w-full justify-center xl:gap-0 gap-4 flex-wrap built-traders wrapper-physical-trad">
            {tradePhysical.card.map((card, i) => (
              <div
                key={i}
                className="rounded-[20px] 2xl:w-[528px] 3xl:w-[530px] xl:w-[347px] lg:w-[331px] md:w-[329px]
                 w-full sm:w-4/6 sm:px-10 px-5  
                 bg-black-dark my-[11px] xl:mr-5 pt-10  2xl:pb-16 pb-9 items-center"
              >
                <div>
                  <p className="text-[28px] 2xl:text-[52px] text-lg 2xl:!leading-[60px] 
                    leading-9 font-semibold text-white md:min-h-[105px]">
                    {card.title}
                  </p>
                  <div className='2xl:min-h-[135px] xl:min-h-[168px] md:min-h-[150px]'>
                    <p className="2xl:text-[23px] xl:text-lg 2xl:mt-10 xl:mt-5 mt-5 text-white 
                   ">
                      <span className='font-bold text-purple'><b>{card.paraTitle}</b></span>
                      {card.desc}
                    </p>
                    {card.info ?  
                      <p className="2xl:text-[23px] xl:text-lg 2xl:mt-9F md:mt-0 mt-6 text-white
                      ">
                        {card.info}
                      </p>
                      : 
                      ''
                    }
                  </div>
                  
                </div>
                <Link to={`${card.url}`} className='xl:text-[23px] text-lg flex items-center gap-2 2xl:mt-9 
                 mt-4 hover:text-purple group transition duration-300 ease-out
                 hover:ease-in max-w-[230px] w-full'>
                  <div  className=" text-white font-extrabold">{card.detail}</div>
                  <img src={arrowimg} alt='' 
                    className='w-6 group-hover:translate-x-2 transition duration-300'/>
                </Link> 
                <img src={card.img} className="xl:mt-10 md:mt-6 mt-5 w-full"
                  alt="" />
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default TradePhysical