import { useState } from 'react';
import topBar from '../../assets/Images/top-bar.svg';
import orderVideo from 'assets/Images/video2.mp4';
import Slider from 'react-slick';

const HowItWork = () => {
  const settings = {
    infinite: true,
    speed: 500,
    fade:true,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [active, setActive ] = useState('one');

  return (
    <>
      <div className="bg-black-darkest pt-[72px] 2xl:pt-20 lg:pt-16 wrapper flex-wrap">
        <div className="w-full block">
          <div className="md:max-w-4xl mb-10">
            <h2 className="inline-block text-white text-overtitle mb-6 tracking-widest">
              HOW <span className="text-gradient">IT WORKS</span>
            </h2>
            <h1 className="text-white md:text-5xl text-3xl">
              Leveraging data and automation to streamline physical trading.
            </h1>
            <p className="text-white md:text-2xl sm:text-xl text-base mt-4">
              Yarta helps trading teams save time, provides unique data-driven market insights, 
              and improves the bottom line. Negotiate, trade, and execute trades with the 
              biggest players in the market with ease.
            </p>
          </div>
        </div>
        <div className={`hidden w-full text-white flex-wrap active-${active}`}>
          <div className='flex w-full overflow-auto'>
            <div className="w-2/6 xl:block hidden"></div>
            <div className="w-full xl:w-4/6 tab-boxes relative flex xl:block">
              <button className="text-white relative z-10 text-sm py-1.5 px-4 transition-colors duration-200 
              ease-in-out whitespace-nowrap"
              onClick={() => setActive('one')}
              >
                Explore Data
              </button>
              <button 
                className="text-white relative z-10 text-sm py-1.5 px-4 transition-colors duration-200 
              ease-in-out whitespace-nowrap"
                onClick={() => setActive('two')}
              >
                Debug Labels
              </button>
              <button 
                className="text-white relative z-10 text-sm py-1.5 px-4 transition-colors duration-200 
              ease-in-out whitespace-nowrap"
                onClick={() => setActive('three')}
              >
                Debug Models
              </button>
              <button 
                className="text-white relative z-10 text-sm py-1.5 px-4 transition-colors duration-200 
              ease-in-out whitespace-nowrap"
                onClick={() => setActive('forth')}
              >
                Mine Edge Cases
              </button>
            </div>
          </div>
          <div className='flex w-full mt-8 flex-wrap'>
            <div className='w-full xl:w-2/6 relative xl:order-1 order-2 xl:mt-0 md:mb-0 mb-6 mt-12'>
              <div className='left-textes opacity-0 absolute w-full'>
                <Slider {...settings}>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Search based on annotations, metadata, model predictions and more with Nucleus’s 
                      powerful querying engine.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      You can also improve the quality of your models by reviewing or editing 
                      your data’s labels.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Nucleus also offers a set of advanced tools to help you explore both your 
                      model and your data.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Did you discover an anomaly? If so, you can use Nucleus to identify 
                      visually similar images.
                    </p>
                  </div>
                </Slider>
              </div>
              <div className='left-textes opacity-0 absolute w-full'>
                <Slider {...settings}>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Search based on annotations, metadata, model predictions and more with Nucleus’s 
                      powerful querying engine.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      You can also improve the quality of your models by reviewing or editing 
                      your data’s labels.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Nucleus also offers a set of advanced tools to help you explore both your 
                      model and your data.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Did you discover an anomaly? If so, you can use Nucleus to identify 
                      visually similar images.
                    </p>
                  </div>
                </Slider>
              </div>
              <div className='left-textes opacity-0 absolute w-full'>
                <Slider {...settings}>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Search based on annotations, metadata, model predictions and more with Nucleus’s 
                      powerful querying engine.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      You can also improve the quality of your models by reviewing or editing 
                      your data’s labels.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Nucleus also offers a set of advanced tools to help you explore both your 
                      model and your data.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Did you discover an anomaly? If so, you can use Nucleus to identify 
                      visually similar images.
                    </p>
                  </div>
                </Slider>
              </div>
              <div className='left-textes opacity-0 absolute w-full'>
                <Slider {...settings}>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Search based on annotations, metadata, model predictions and more with Nucleus’s 
                      powerful querying engine.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      You can also improve the quality of your models by reviewing or editing 
                      your data’s labels.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Nucleus also offers a set of advanced tools to help you explore both your 
                      model and your data.
                    </p>
                  </div>
                  <div>
                    <p className="max-w-[100%] xl:max-w-[70%] pt-3 pb-3 pl-2 relative border-l border-purple">
                      Did you discover an anomaly? If so, you can use Nucleus to identify 
                      visually similar images.
                    </p>
                  </div>
                </Slider>
              </div>
            </div>
            <div className='w-full xl:w-4/6 xl:order-2 order-1'>
              <div className='w-full hidden video-boxes'>
                <img src={topBar} />
                <div className='h-80 overflow-hidden'>
                  <video
                    src={orderVideo}
                    width="100%"
                    className="rounded-md "
                    autoPlay
                    loop
                    muted
                  >
                    Your Browser does not support videos
                  </video>
                </div>
              </div>
              <div className='w-full hidden video-boxes'>
                <img src={topBar} />
                <div className='h-80 overflow-hidden'>
                  <video
                    src={orderVideo}
                    width="100%"
                    className="rounded-md "
                    autoPlay
                    loop
                    muted
                  >
                    Your Browser does not support videos
                  </video>
                </div>
              </div>
              <div className='w-full hidden video-boxes'>
                <img src={topBar} />
                <div className='h-80 overflow-hidden'>
                  <video
                    src={orderVideo}
                    width="100%"
                    className="rounded-md "
                    autoPlay
                    loop
                    muted
                  >
                    Your Browser does not support videos
                  </video>
                </div>
              </div>
              <div className='w-full hidden video-boxes'>
                <img src={topBar} />
                <div className='h-80 overflow-hidden'>
                  <video
                    src={orderVideo}
                    width="100%"
                    className="rounded-md "
                    autoPlay
                    loop
                    muted
                  >
                    Your Browser does not support videos
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;
