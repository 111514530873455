/* eslint-disable max-len */
import {
  Money,
  Bag,
  Connection,
  Market,
  Secure,
  Custom,
  Article,
  Groups,
  Help,
  Work,
  Cube,
} from '../assets/Icons';
import Simplify from '../assets/Images/simplify.png'
import Elevent from '../assets/Images/elevate.png'
import Digites from '../assets/Images/digitise.png'
import Connect from '../assets/Images/connect.png'
import Nagociate from '../assets/Images/nagociate.png'
import Trade from '../assets/Images/trade.png'
import User from '../assets/Images/user.png'
import Community from '../assets/Images/connecting.png'
import Weather from '../assets/Icons/Weather'
import Mail from '../assets/Icons/Mail'
import Calendar from '../assets/Icons/Calendar'
import Chat from '../assets/Icons/Chat'
import Survey from '../assets/Icons/Survey'
// import Calculator from '../assets/Icons/Calculator'
// import Rank from '../assets/Icons/Rank'
// import SuitcaseBox from '../assets/Icons/SuitcaseBox'
import Mic from '../assets/Icons/Mic'
import Chart from '../assets/Icons/Chart'
import Track from '../assets/Icons/Track'

export const NavLinks = [
  {
    title: 'Platforms',
    subcategories: [
      {
        title: 'Yarta Trade',
        subtitle: 'Trade global grain markets',
        link: '/trade',
        icon: Cube,
      },
      {
        title: 'Yarta Market Intel',
        subtitle: 'Stay informed with data',
        link: '/market_intel',
        icon: Chart,
      },
      {
        title: 'Yarta Logistics',
        subtitle: 'Move livestock with ease',
        link: '/logistics',
        icon: Track,
      },
    ],
  },
  {
    title: 'Company',
    subcategories: [
      {
        title: 'About',
        subtitle: 'Learn more about our team',
        link: '/about',
        icon: Groups,
      },
      {
        title: 'Careers',
        subtitle: 'Come work with us',
        link: '/careers',
        icon: Work,
      },
      {
        title: 'Blog',
        subtitle: 'Read our latest updates',
        externalLink: 'https://blog.yarta.io/',
        icon: Article,
      },
    ],
  },
  {
    title: 'Resources',
    subcategories: [
      {
        title: 'FAQ',
        subtitle: 'Read our Frequently Asked Questions',
        link: '/help-center',
        icon: Help,
      },  
      {
        title: 'Market Quotes Board',
        subtitle: 'Track global markets in real-time',
        link: '/market_quotes_board',
        icon: Mic,
      },     
      {
        title: 'Podcast',
        subtitle: 'Deciphering industry and market news',
        link: '/podcast',
        icon: Mic,
      }, 
      {
        title: 'Market Chats',
        subtitle: 'Free market intel chats',
        link: '/chat',
        icon: Chat,
      },
      {
        title: 'Market Surveys',
        subtitle: 'Track trader market sentiment',
        link: '/survey',
        icon: Survey,
      },   
      {
        title: 'Yarta Daily',
        subtitle: 'Subscribe to the daily market digest email',
        link: '/daily_email',
        icon: Mail,
      },
      {
        title: 'Industry Events',
        subtitle: 'Upcoming industry conferences and events',
        link: '/events',
        icon: Calendar,
      },
            
      // {
      //   title: 'Jobs Board',
      //   subtitle: 'Connect with the largest employers in ag',
      //   link: '/jobs_board',
      //   icon: SuitcaseBox,
      // },
      // {
      //   title: 'Calculators',
      //   subtitle: 'Useful production and trading calculators',
      //   link: '/help-center',
      //   icon: Calculator,
      // },
      // {
      //   title: 'Rankings',
      //   subtitle: 'Monthly roundups of the top ag movers and shakers',
      //   link: '/rankings',
      //   icon: Rank,
      // },     
    ],
  },
  {
    title: 'Contact',
    subcategories: [
      {
        title: 'Get in touch',
        subtitle: 'Learn more about our team',
        link: '/contact',
        icon: Groups,
      },      
    ],
  },
  
];
export const Content = {
  tradeWithEase: {
    title: 'Trade physical agricultural commodities ',
    mobileTitle: 'with confidence',
    desc: 'The Yarta Trade platform is a platform providing traders of physical agricultural commodities a streamlined, reliable and enhanced trading experience.',
  },
  helpCenter: {
    Basics: [
      {
        title: 'What is Yarta?',
        subtitle: [
          'Yarta is a physical commodity trading platform that enables companies to easily exchange agricultural commodities.',
          'For traders, that means a private, secure and simple platform that saves time and money.',
          'As a young company we have big ambitions and we are striving to be a trusted partner to help you grow. We are building out additional services in the coming months to support your trading; news and analytics, post trade management, and financing.',
          'Whether you’re an ender user, hedge fund or exporter - Yarta allows you to trade on your terms.',
        ],
      },
      {
        title: 'How does Yarta work?',
        subtitle: [
          'For companies who trade Australian physical cash agricultural markets, getting approved to trade on the platform takes minutes.',
          'Simply complete the three step sign-up process, select your approved counterparties that you would like to trade with, and within minutes you can be negotiating and trading with the biggest traders in the market.',
          'At this time, you can trade the major grain commodities across the major types of markets such as Bulk Basis Domestic Track, Delivered Container Terminal (DCT) and CFR-CIF for Grain in Containers.',
          'Price discovery is derived from market participants creating a Request For Quote (RFQ). This is an order where a market participant anonymously expresses interest in buying or selling a particular commodity, location spread or quality spread.',
          'By creating an RFQ it invites other market participants to negotiate anonymously in chat to arrange bespoke transactions with trusted counterparties.',
        ],
      },
      {
        title: 'Is Yarta right for my company?',
        span: {
          beforeSpan:
            'The best way to see what Yarta can do for your business is to go through our ',
          span: 'simple sign-up process',
          afterSpan:
            'which takes just a few minutes. There’s no cost to join Yarta and no obligation to ever make a trade.        ',
        },
        subtitle: [
          'If you trade physical agricultural cash commodity markets and you’re looking to take your trading to the next level - yes! Yarta is built for companies of all shapes and sizes, from niche end users to the largest publicly traded companies and everything in between.',
        ],
      },

      {
        title: 'Is Yarta available for companies outside of Australia?',
        subtitle: [
          'Yes! As long as you have an Australian subsidiary, we can work with you. ',
          'Yes! If you’re looking to trade Australian grain and as you have an Australian subsidiary, we can work with you.',
          'If you’re not eligible today, please contact hello@yarta.io via email and we can notify you as soon as we launch in your country.',
          'Additionally, we will be expanding to other commodity sectors internationally soon. Please get in contact if you’d like to see our platform operating in a segment that you trade.',
        ],
      },
    ],
    Pricing: [
      {
        title: 'Does it cost anything to sign up?',
        subtitle: [
          'No, there’s no cost to join Yarta, and no obligation to make a trade once you have an account. Signing up takes just a couple of minutes, and you’ll be able to see all the active orders in the marketplace.',
        ],
      },
      {
        title: 'How much does it cost to trade?',
        subtitle: [
          'You will only be charged a brokerage fee if you transact as the Request For Quote creator. ',
          'A Request For Quote (RFQ) is an order where a market participant anonymously expresses interest in buying or selling a particular commodity, location spread or quality spread.',
        ],
      },
    ],
    Trading: [
      {
        title: 'What markets are available to trade?',
        subtitle: [
          'We offer trading cash commodity trading for the major Australian agricultural commodities. This includes Domestic Basis Track, Delivered Container Terminal (DCT) and CFR-CIF for Grain in Containers.',
          'We are expecting to expand this based on market feedback.',
          'We offer the ability to execute long or short trades via flat price, quality spreads or location spreads.',
        ],
      },
      {
        title: 'Can I trade with just approved counterparties?',
        subtitle: [
          'Yes, you’ll be able to see which orders belong to approved counterparties. The platform works on a discreet basis with the counterparty only being revealed post transaction confirmation.',
          'To select which counterparties you approve, you can head to the Dashboard section and select “Approved Counterparties”. From there you will select the approved counterparties from the list.',
        ],
      },
      {
        title: 'How does the brokerage fee work?',
        subtitle: [
          'You will only be charged a brokerage fee if you transact as the Request For Quote creator.',
          'A Request For Quote (RFQ) is an order where a market participant anonymously expresses interest in buying or selling a particular commodity, location spread or quality spread.',
          'The cost for executing a trade as an RFQ creator is $0.40/mt.',
          'Your brokerage costs will be aggregated and invoiced at the end of the month via email.',
        ],
      },
      {
        title: 'Will I be notified when there is activity regarding my order?',
        subtitle: [
          'Yes, you’ll be notified via the platform chat and additionally via email for any trading activity.',
        ],
      },
      {
        title:
          'How can I edit or review outstanding orders and check completed trades?',
        subtitle: [
          'You’ll be able to review, edit, or cancel the different active orders that you have created.',
          'Additionally, you will see the completed trades with the full trade details.',
        ],
      },
    ],
    contact: [
      'If you have any questions about our products or services, or you’d like help opening an account, please reach out.',
      'We’re around 24 hours a day from Monday 6am to Friday 5pm Sydney time.',
      'The fastest way to contact us is to reach out on chat in the bottom right hand side corner of this page.',
      'Phone: +61 (02) 72 28 8755',
      'Get in touch with the team via email by filling out the form to the right.'
    ],
  },
  builtByTraders: {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        iconName: Money,
        title: 'Low Fees ',
        desc: 'Fees onYarta Trade platform are cheaper than traditional brokerage providers with added benefits.',
      },
      {
        iconName: Market,
        title: '24/7 Market ',
        desc: 'Seize market opportunities at any time, because you shouldn’t have to wait.',
      },
      {
        iconName: Custom,
        title: 'Fully Customisable ',
        desc: 'Negotiate to secure the best price and flexible terms to help you achieve your trading goals.',
      },
      {
        iconName: Bag,
        title: 'Streamline Workflow ',
        desc: 'Turbo charge productivity by negotiating and executing in one central platform.',
      },
      {
        iconName: Connection,
        title: 'Enhanced Execution ',
        desc: 'Bespoke deal terms at size with the biggest counterparties in the market.',
      },
      {
        iconName: Secure,
        title: 'Secure and Reliable ',
        desc: 'Audited by security teams, trust and privacy are paramount to us.',
      },
    ],
  },
  newHomeMission: {
    title: 'Our mission',
    description:
      'Empowering farmers and traders with a central platform to research, connect and trade commodities globally.',
    missions: [
      'No gimmicks.',
      'No rent-seekers.',
      'As a trusted partner, helping you grow on your terms.',
    ],
  },
  centralEcosystem: {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        img: Nagociate ,
        title: 'The Yarta Trade Platform ',
        desc: 'Negotiate trades anonymously with approved counterparties. Streamline KYC and post-trade execution.',
      },
      {
        img: Trade,
        title: 'The Yarta Market Intel Platform ',
        desc: 'Stay informed with everything you need at your fingertips; live news, global fundamental data and charts, satellite data, and much more.  ',
      },
      {
        img: User,
        title: 'The Yarta Logistics Platform',
        desc: 'Making life easier for the North American livestock sector seamlessly connecting and automating key processes for buyers, sellers, vets and freight companies.',
      },
      {
        img: Community,
        title: 'The value add for the community',
        desc: 'Connecting traders, farmers, and end users with market chats, calculators, industry events, and even a jobs board.',
      },
    ],
  },
  tradePhysical : {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        title: 'Trade physical grain markets with ease',
        paraTitle:'Simplify. ',
        desc: 'Negotiate and trade global grain markets with KYC approved counterparties all via our simple and intuitive encrypted chat.',
        info:'Currently open to beta testers.',
        detail:'Find out more ',
        url:'/trade',
        img:Digites,
      },
      {
        title: 'Improve your trading decision making with data',
        paraTitle:'Elevate. ',
        desc: 'Trading is hard work. We help you focus on what matters as your outsourced research team.',       
        detail:'Find out more ',
        url:'/market_intel',
        img:Elevent,
      },
      {
        title: 'Turbocharge logistics productivity',
        paraTitle:'Digitise. ',
        desc: 'We’re removing the monotonous and time consuming manual work for companies moving livestock throughout North America. Open to beta testers in November.',
        detail:'Find out more ',
        url:'logistics',        
        img:Simplify,
      },
      {
        title: 'Connect and grow with the Yarta community',
        paraTitle:'Connect. ',
        desc: 'Be part of our community and receive exclusive insights, job opportunities, content, industry events and so much more. This is the Yarta value add.',
        detail:'Find out more ',
        url:'/events',
        img:Connect,
      },
    ],
  },
  newHome: {
    tradeDirectly: {
      titleBeforeSpan: 'Trade commodities',      
      titleSpan: 'the smart' ,
      subtitle:'Refocus your efforts on what really matters.',
      description:'Trade global physical agricultural markets with ease, access data driven insights to stay informed, and automate post-trade logistics.',
    },
    tradeDirect: {
      title: 'Powerful data and analytics',
      subtitle:
      'Tap in to growing list of fundamental and alternative data, proprietary and third party research and analytics.',     
    },
    tradeCash: {
      title:
        'Custom live news so you never miss a beat',
      subtitle:
        'Stream filtered real time news and analyse the impact of global news in over 100 languages across print, tv and web formats.',
    },
  },
  TradeMarket: {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        iconName: Money,
        title: 'Low Fees',
        desc: 'Fees on Yarta Trade platform are cheaper than traditional brokerage providers with market intel added benefits.',
      },
      {
        iconName: Market,
        title: '24/7 Market',
        desc: 'Seize market opportunities at any time, because you shouldn’t have to wait.',
      },
      {
        iconName: Custom,
        title: 'Fully Customisable',
        desc: 'Negotiate to secure the best price and flexible terms to help you achieve your trading goals.',
      },
      {
        iconName: Bag,
        title: 'Streamline Workflow ',
        desc: 'Turbo charge productivity by negotiating and executing in one central platform.',
      },
      {
        iconName: Connection,
        title: 'Enhanced Execution ',
        desc: ' Bespoke deal terms at size with the biggest counterparties in the market.',
      },
      {
        iconName: Secure,
        title: 'Secure and Reliable ',
        desc: 'Audited by security teams, trust and privacy are paramount to us.',
      },
    ],
  },
  builtByFarmer: {       
    card: [
      {
        iconName: Money,
        title: 'Jobs Board ',
        desc: 'Looking for a job in agriculture? We bring together global ag companies and people looking for a new gig, with a particular focus on remote agriculture jobs',
        detail:'Coming Soon',
        link: '/chat'       
      },
      {
        iconName: Custom,
        title: 'Free Market Chats ',
        desc: 'Want market updates sent to you directly as they occur? Come check out the Telegram chat community we’re building.',
        detail:'View Chats',
        link: '/chat'       
      },
      {
        iconName:  Secure,
        title: 'Farm and Market Calculators ',
        desc: 'Check out the free simple calculators we’re building for farm production, financial farm analysis/breakevens and trading calculators.',
        detail:'Coming Soon',
        link: '/'
      },
      {
        iconName: Connection,
        title: 'Industry Events',
        desc: 'Want to know what industry events and calendars are coming up on the calendar?',
        detail:'View Events',
        link: '/Events'
      },
      {
        iconName: Bag,
        title: 'Rankings ',
        desc: 'Monthly roundups of the movers and shakers in the global agriculture industry.',
        detail:'Coming Soon',
        link: ''
      },
      {
        iconName: Market,
        title: 'View Blog',
        desc: 'Check out our blog as we build out guides for farmers and traders navigating global commodity markets.',
        detail:'View blogs',
        link: '/trade'
      },
    ],
  },
  ourMission: {
    title: 'Our mission',
    description:
      'Empowering farmers and traders with a platform to trade, finance and hedge their global agricultural commodities.',
    missions: [
      'No gimmicks.',
      'No rent-seekers.',
      'As a trusted partner, helping you grow on your terms.',
    ],
  },
  startTradingToday: {
    title: 'Streamline your trading',
    description:
      'Our online platform is designed to save you time by providing you with physical grain opportunities where and when you need it.',
  },
  programTypes: {
    title: 'program types',
    description: [
      {
        title: 'active',
        text: 'The active strategy leverages our extensive network in to the global agricultural supply chain coupled with a sophisticated data driven approach. We’ll look to optimise returns and out perform the benchmark average based on our global view of supply/demand dynamics and macroeconomic factors. You’ll be able to select the risk tolerance that suits your needs.  We offer three levels of risk tolerance from conservative where we hedge often, to growth where we hedge less and try to time the market, to balanced that sits in between.',
      },
      {
        title: 'passive',
        text: 'The passive approach is for those who want to put their marketing on autopilot and let us sell equal portions each month. This will ensure you’ll dollar cost average over the life of the program, selling at regular intervals, evening out market fluctuations.',
      },
    ],
  },
  transferGrain: {
    title: 'How Do You transfer your grain?',
    desc: 'We make it simple to transfer your grain into the managed pricing programs. We’ve got storage and handling agreements with all the major bulk handlers across NSW, Victoria, and South Australia.',
  },
  videoSection: {
    desc: 'Voluptatem porro cum doloribus. Quia ipsum suscipit aut rerum cum. Sequi iure distinctio animi ipsam esse quae totam. Eum quis veniam odio cum autem. Quas temporibus aliquid. Dolorem officiis tempora sapiente. Suscipit aliquam consequatur. Dolor sunt ipsum qui a neque officia.',
  },
  contractsAvailable: {
    title: 'contracts available',
    card: [
      {
        price: '$100',
        duration: '1-Year Contract',
        startDate: 'Start: August',
        description:
          'A one-year marketing period for a summer delivery of the crop. Marketing period: Aug 1 - June 15 of the following year',
        offers: [
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
        ],
      },
      {
        price: '$200',
        duration: '2-Year Contract',
        startDate: 'Start: August',
        description:
          'A one-year marketing period for a summer delivery of the crop. Marketing period: Aug 1 - June 15 of the following year',
        offers: [
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
        ],
      },
      {
        price: '$250',
        duration: '3-Year Contract',
        startDate: 'Start: August',
        description:
          'A one-year marketing period for a summer delivery of the crop. Marketing period: Aug 1 - June 15 of the following year',
        offers: [
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
          'Magnam quod iusto repellendus saepe. Aut aut excepturi qui blanditiis',
        ],
      },
    ],
  },
  grainExchange: {
    tradeDirectly: {
      title: 'Trade discreetly and securely with approved counterparties',
      subtitle:
      'Use our encrypted chat to negotiate to get the deal you want with ease. No emails. No phone calls.',
      description:
      'No precious time wasted.',
    },
    tradeCash: {
      title:
        'Trade a range of cash grain markets all in one easy to use location ',
      subtitle:
        'We offer the ability to trade across the full spectrum of cash grain markets in Australia and will be expanding further in coming months.',
    },
  },
  resources: {
    tradeDirectly: {
      titleBeforeSpan: 'Helping you',
      title: 'on your terms.',
      titleSpan: 'grow',
      subtitle:'As your trusted partner, we’re here to help you grow your business.',
      description:'We’re building free resources as part of the',
      descriptionspan: 'Yarta value add.'
    },
    tradeDirect: {
      title: 'Ready for a new challenge?',
      subtitle:
      'Connect with employers looking for talent in the global agriculture sector. Everything from the biggest employers to the hottest up and coming startups.',     
    },
    tradeCash: {
      title:
        'The most important data releases streamed in real time',
      subtitle:
        'Join our telegram chats and receive commodity and economic related data releases as they happen.',
    },
  },
  blockplatform : {
    tradeDirectly: {
      titleBeforeSpan: 'Trade physical agricultural commodities',      
      titleSpan: 'with ease',
      subtitle:'Yarta Trade is a platform providing traders of physical agricultural commodities with a streamlined, reliable, and enhanced trading experience.',   
      
    },
    tradeDirect: {
      title: 'Trade discreetly and securely with approved counterparties',
      subtitle:'Use our encrupted chat to negotiate to get the deal you want with ease. No emails. No phone calls. No precious time wasted.',     
    },
    tradeCash: {
      title:
        'Trade a range of cash grain markets all in one easy to use location',
      subtitle:
        'We offer the ability to trade across the full spectrum of cash grain markets in Australia, and will be expanding further, and will soon be expanding globally.',
    },
    tradeplatform: {
      title:
        'Want to trade without negotiating? We have that too',
      subtitle:
        'Introducing Yarta QuickTrade where you can see all the markets in one interface and quickly execute a trade by hitting a bid or lifting an offer.',
    },      
    
  },
  farmerandtraders: {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        iconName: Money,
        title: 'Fundamental Data',
        desc: 'Review macroeconomic and commodity-related data points. Covers key global supply and demand data for over 20 commodities.',
      },
      {
        iconName: Custom,
        title: 'Stream Live News',
        desc: 'Set up custom dashboards by search term, country, language, or topic to track real-time news. Never miss a market-moving headline again.',
      },
      {
        iconName: Weather,
        title: 'Weather',
        desc: 'We’ve done the hard work to bring global weather maps and live weather to one location. Covers precipitation, temperature, radars, soil moisture, vegetation info.',
      },
      {
        iconName: Connection,
        title: 'Seasonality and Other Tools',
        desc: 'Take our large database of data and slice and dice it to help inform your market decisions.',
      },
      {
        iconName: Bag,
        title: 'Market Data',
        desc: 'We track futures and esoteric cash markets so you can keep your finger on the pulse.',
      },
      {
        iconName: Market,
        title: 'Alternative Data',
        desc: 'Giving you the edge on your competition, our alt data tools such as vessel tracking, global news sentiment, and volume along with search metrics give you insight into what is really driving the market.',
      },
    ],
  },
  encrypted: {
    title: 'by traders, for traders',
    desc1:
      'trading platform that reimagines how commodities can be traded, financed and hedged.',
    desc2:
      'physical agricultural commodities with low transaction fees, enhanced privacy, and access to the biggest players in the market.',
    desc2Span: 'Buy and sell  ',
    desc1Span: 'high-performance commodity  ',
    descBeforeSpan: 'We’re building a  ',
    card: [
      {
        iconName: Money,
        title: 'Chat, Collaborate and Trade',
        desc: 'Use our encrypted chat to chat with brokers and service providers.',
        span: 'Connect. '
      },
      {
        iconName: Market,
        title: 'Never Miss An Opportunity',
        desc: 'Get what you need, when you need via chat negotiations.',
        span: 'Trade. '
      },
      {
        iconName: Custom,
        title: 'Automate and Streamline',
        desc: 'Organise the movement of the animals with vets and freight companies.',
        span: 'Move. '
      },
      {
        iconName: Bag,
        title: 'Track and Manage',
        desc: 'Manage the deliveries of animals and the digitise grading.',
        span: 'Track. '
      },
    ],
  },
  marketplatform : {
    tradeDirectly: {
      titleBeforeSpan: 'Yarta is your',      
      titleSpan: 'outsourced research team',
      subtitle:'Stay informed on global commodity markets with one place for news, weather, prices, fundamental and alternative data.',   
      
    },
    tradeDirect: {
      title: 'Trade discreetly and securely with approved counterparties',
      subtitle:'Use our encrupted chat to negotiate to get the deal you want with ease. No emails. No phone calls. No precious time wasted.',     
    },
    tradeCash: {
      title:
        'Trade a range of cash grain markets all in one easy to use location',
      subtitle:
        'We offere the ability to trade across the full spectrum of cash grain markets in Australia and will be expanding further and will soon be expanding globally. ',
    },
    tradeplatform: {
      title:
        'Want to trade without negotiating? We have that too',
      subtitle:
        'Introducing Yarta QuickTrade where you can see all the markets in one interface and quickly execute a trade by hitting a bid or lifting an offer.',
    },      
    
  },
  logistics : {
    tradeDirectly: {
      titleBeforeSpan: 'Securely trade, manage, and move ',      
      titleSpan: 'livestock',
      subtitle:'Leave behind the chaotic mix of calls, emails, and messages for good.',   
      subtitleInfo:'Everything you need in one easy to use platform.',
      
    },
    tradeDirect: {
      title: 'Trade discreetly and securely with approved counterparties',
      subtitle:'Use our encrupted chat to negotiate to get the deal you want with ease. No emails. No phone calls. No precious time wasted.',     
    },
    tradeCash: {
      title:
        'Trade a range of cash grain markets all in one easy to use location',
      subtitle:
        'We offere the ability to trade across the full spectrum of cash grain markets in Australia and will be expanding further and will soon be expanding globally. ',
    },
    tradeplatform: {
      title:
        'Want to trade without negotiating? We have that too',
      subtitle:
        'Introducing Yarta QuickTrade where you can see all the markets in one interface and quickly execute a trade by hitting a bid or lifting an offer.',
    },      
    
  },
  footer: {
    email: 'hello@yarta.com',
    copy: '2022 Yarta Exchange. All rights reserved.',
    title: 'Keep in touch',
    subtitle:
      'Feel free to',
    subtitlespan:
      ' reach out ',
    subtitle2:
      'if you have questions, feedback, or simply have a chat.',
    socials: [
      { title: 'Twitter', href: 'https://twitter.com/yarta_io' },
      { title: 'Linkedin', href: 'https://www.linkedin.com/company/yarta' },
    ],
  },
  footerLinks: {
    Company: [
      {
        title: 'About',
        link: '/about',
        icon: Groups,
      },
      {
        title: 'Careers',
        link: '/careers',
        icon: Work,
      },
      // {
      //   title: 'Blogs',
      //   link: 'https://blog.yarta.io/',
      //   externalLink: 'https://blog.yarta.io/',
      //   icon: Article,
      // },
    ],
    Platforms: [
      {
        title: 'Yarta Trade',
        subtitle: '',
        link: '/trade',
        icon: Groups,
      },
      {
        title: 'Yarta Market Intel',        
        link: '/market_intel',
        icon: Groups,
      },
      {
        title: 'Yarta Logistics',        
        link: '/logistics',
        icon: Groups,
      }
    ],
    Resources: [ 
      // {
      //   title: 'Jobs Board',        
      //   link: '/',
      //   icon: Help,
      // },
      {
        title: 'Industry Events',        
        link: '/events',
        icon: Help,
      },
      {
        title: 'Market Chats',        
        link: '/chat',
        icon: Help,
      },
      {
        title: 'Market Surveys',          
        link: '/survey',
        icon: Help,
      },
      // {
      //   title: 'Calculators',        
      //   link: '/help-center',
      //   icon: Help,
      // },
      // {
      //   title: 'Rankings',        
      //   link: '/',
      //   icon: Help,
      // },
      {
        title: 'Yarta Daily',        
        link: '/daily_email',
        icon: Help,
      },
      {
        title: 'Podcast',        
        link: '/podcast',
        icon: Help,
      },
      {
        title: 'FAQ',        
        link: '/help-center',
        icon: Help,
      },
      {
        title: 'Yarta Blog',        
        link: 'https://blog.yarta.io/',
        icon: Groups,
      },      
    ],
    Contact: [
      {
        title: 'Get in touch',
        subtitle: '',
        link: '/contact',
        icon: Groups,
      },      
    ],
  },
  faq: {
    title: 'FAQ',
  },
};
