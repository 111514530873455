/* eslint-disable max-len */
import { FC } from 'react';
import Mails from '../Images/mail.png';

export const Mail: FC = () => {
  return (
    <>
      <img src={Mails} alt='' 
        className='w-6'/>
    </>
  );
};

export default Mail;
